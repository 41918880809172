@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  color: rgb(0 0 0);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 26px;
  overflow-x: hidden;
  font-family: "Franklin Gothic Book";
}

ul,
.coreadvantages .squarecont ul li ul li,
.packstn .squarecont li span,
.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
.medright .react-tabs ul li:last-child {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

* {
  outline: 0;
  color: #000;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
}

section,
.containers,
.title,
.butn,
.buttnprop span,
.heading,
.plans,
.planslider,
.pline,
.gradientapp,
.appcta,
.featurerow,
.number,
.count,
.technology,
.buttnprop a,
.jabtout,
.pricestn h3,
.pricestn,
.entertainment,
.banner,
.circleicons ul,
.circleicons,
.form li,
.squareshape,
.squarecont,
.accordion-item,
.advantages,
.prize,
.booknow,
.gradienticons,
.gradienticons ul li p,
.plancont p,
.bundlestn,
.hemburgermenus .sitemapcol,
.coreadvantages .squarecont ul li ul li,
.planbanner,
.androidtvrow,
.androidtv,
.bundlebox .entertainment,
.bundlebox .entertainment ul,
.highlights,
.labelcont,
.partnerbox ul,
.partnerbox,
.androidfeature,
.media,
.mediastn,
.react-tabs__tab-list,
.planstabpanel,
.hungamabox,
.jujharnumber,
.mediastn,
.lg-react-element,
.solutionrow,
.circleicons ul li h5,
.protechnology,
.technoimg,
.technoimg img,
.technocont,
.btechnoimg h4,
.btechnoimg,
.btechnocont,
.heading h4 {
  width: 100%;
  float: left;
}

.buttnprop a,
.form li,
.bannercont ul,
.packmodule {
  display: flex;
}

.image_plans_div,
.pricestn .booknow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.gradienticons ul li p {
  margin-top: 10px;
}

.image_plans_div P {
  position: absolute;
  transform: rotate(-3deg);
  top: 35%;
  right: 0;
  color: white;
  font-size: 32px;
  font-weight: 700;
  z-index: 3;
  width: 100%;
  text-align: center;
  margin: 0;
}

.image_plans_div p span {
  font-size: 15px;
}

.image_plan:after {
  content: "";
  background-image: url(../images/Plans_\ effect.webp);
  position: absolute;
  width: 100%;
  top: 8%;
  right: 0;
  left: 0;
  height: 100%;
  background-size: contain;
  z-index: 5;
}

.pricestn li:nth-child(1):before {
  content: '\2715';
  position: absolute;
  color: #000;
  font-size: 50px;
  left: 20%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chooseplan,
.coreadvantages .squarecont ul li ul li {
  padding-top: 0;
}

.title,
.react-tabs__tab-list,
.price,
.heading,
.planstab,
.featurerow,
.advantages,
.hdbundle .gradienticons,
.booknow.filter .footerform .form ul li,
.medright .react-tabs ul,
.entertainment {
  text-align: center;
  margin-bottom: 50px;
}

.jujhar a,
.containers,
.butn,
.buttnprop span,
.heading,
.react-tabs__tab-list,
.ptag,
.plansitem,
.gradientapp,
.apprgt,
.appcta,
.featurestn,
.featurerow,
.number,
.count,
.technology,
.technology ul,
.jabtout,
.sliderrow,
.pricestn,
.circle01,
.booknow,
.serviceimg,
.circle02,
.advantagesbox,
.prize,
.gradienticons ul li span,
.androidtvrow,
.planbanner,
.plancont,
.plans.smarthome .count .App,
.circleicons ul li,
.advantagecont,
.advantageicon span,
.circleicons ul li span,
.androidtvrow3,
.gradienticons ul li,
.gradienticons ul,
.packstn .squarecont li,
.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
.bundlebox .entertainment ul,
.highlights ul,
.countnum,
.partnerbox ul,
.androidbox ul li,
.hungamacircle,
.hungamabox,
.hungamafrow,
.lg-react-element,
.ottimg,
.solutionrow,
.protechnology {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plansitem,
.featurestn,
.technology ul,
.pricestn,
.circle01,
.advantagesbox,
.plancont,
.plans.smarthome .count,
.plans.smarthome .circleicons ul,
.bundlestn,
.advantages,
.circleicons ul li,
.gradienticons ul li,
.coreadvantages .squarecont ul li ul,
.packstn .squarecont li,
.countnum,
.partnerbox ul,
.androidbox ul li,
.hungamacircle,
.hungamafrow,
.lg-react-element,
.circleicons ul,
.protechnology,
.heading {
  flex-wrap: wrap;
}

.banner.mngslide .sliderrow,
.featurerow:nth-child(even),
.slick-track .slick-slide:nth-child(even) .sliderrow,
.jabtout.advantagestn,
.androidtvrow:nth-child(even),
.hungamabox:nth-child(even) {
  flex-direction: row-reverse;
}

.heading,
.plans,
.pline ul li span:nth-child(1),
.featurebox,
.logo,
.topcta ul li,
.slidecont,
.aboutus,
.featurebox,
.feature,
.app,
.service,
.technologystn,
.androidtvrow,
.banner,
.image_plans_div,
.containers svg,
.buttnprop span,
.heading h4,
.price h6,
.apprgt,
.appleft,
.App,
.pricestn li,
.circlimg,
.listpoint,
.logo,
.slideimg img,
.price ul li,
.advantagesbox span,
.bundlebox,
.bundlebox ul li,
.androidfeature ul li span,
.retail,
.broadband,
.squareshape,
.enterprise,
.partner,
.androidtv,
.coreadvantages .squarecont ul li ul li,
.footerform .form ul li,
.App,
.androidbox,
.hdbundle,
.businesstype .technobox {
  position: relative;
}

.apprgt {
  justify-content: flex-end;
}

.servicecont .butn,
.appcta,
.jabcont .butn,
.pricestn,
.buttnprop,
.androidfeature ul li h4,
.andoidcont h4,
.pricestn h3,
.bannercont ul {
  justify-content: flex-start;
  margin-top: 25px;
}

section,
.chooseplan,
.plans,
.faq .container,
.androidtvrow,
.number.aboutnetplus .container,
.business {
  padding: 60px 0;
}

.featurestn {
  width: 75%;
  justify-content: space-evenly;
  margin: 0px auto;

}

.medright {
  width: 75%;
}

.squarecont li span,
.bgcolor,

.title h2,
.title h3 {
  background: linear-gradient(141deg, rgba(106, 61, 226, 1) 31%, rgba(175, 44, 126, 1) 50%, #ec3047 75%);
}

/* marquee{
  font-size: 25px;
  
  color:#cf18a1;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
 
  } */

.androidtvrow:before,
.squarecont li,
.bgcolor1,
.faq .container {
  background-image: linear-gradient(20deg, #e7ceeb, #fed0d3);
}

.container {
  max-width: 92%;
}

.jujhar a img,
.title p,
.title h2,
.title h3 {
  width: 60%;
  margin: 0px auto
}

.netpluslide,
.servicebox,
.plansitem,
.planslider,
.technology ul,
.jabtout,
.faqcont,
.aboutnetplus .title p,
.plansitem .entertainment,
.jujhar svg {
  width: 80%;
  margin: 0px auto;
}

.servicecont p {
  width: 90%;
  margin: 0;
  height: 80px;
}

.web-container {
  width: 77%;
  margin: 0px auto;
}

.planslider,
.jabtout {
  margin: 0 10%;
}

.butn,
.servicecont,
.plansitem,
.count,
.booknow,
.androidpoints,
.filter .footerform .form ul li:last-child {
  margin-top: 50px;
}

.banner:before {
  content: "";
  background-image: url(../images/Doodle3.webp);
  position: absolute;
  z-index: 1;
  left: -6%;
  height: 200px;
  background-repeat: no-repeat;
  width: 200px;
  top: -12%;
  background-size: cover;
  background-position: bottom;
}

#banner:before {
  content: "";
  background-image: url(../images/hom_banner_backgroun.webp);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  background-size: cover;
  background-position: bottom;
  transition: ease all 1.5s;
}

#banner.aos-init.aos-animate:before {
  height: 100%;
}

#banner:after {
  content: "";
  background-image: url(../images/Doodle1.webp);
  position: absolute;
  width: 20%;
  z-index: 5;
  height: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  top: 50%;
  right: -15%;
  background-size: contain;
}

.containers svg {
  width: 1.35rem;
  height: 1.35rem;
  top: 2px;
  margin-right: 5px;
}

.check #border {
  fill: #fff;
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
}

.check #dot {
  fill: url(#gradient);
  transform: scale(0);
  transform-origin: 50% 50%;
}

.containers input,
.slick-next:before,
[dir="rtl"] .slick-prev:before,
.slick-next:before,
.slick-prev:before,
.App:nth-child(1):before,
.hemburgermenus .dropdown::before,
.navbar ul li:nth-child(6) a.active:before,
.btechnocont li:last-child:before,
.technocont ul li:last-child:before {
  display: none;
}

input:checked+label svg #border {
  stroke: url(#gradient);
  stroke-dasharray: 145;
  stroke-dashoffset: 145;
  animation: checked 500ms ease forwards;
}

input:checked+label svg #dot {
  transform: scale(1);
  transition: transform 500ms cubic-bezier(0.57, 0.21, 0.69, 3.25);
}

@keyframes checked {
  to {
    stroke-dashoffset: 0;
  }
}

.faq .container,
.androidfeature ul li span img,
.btechnoimg {
  border-radius: 25px;
}

.chooseplan {
  border-radius: 100px;
}

.hdbundle::before,
.partner::before,
.enterprise::before,
.squareshape::before,
.broadband::before,
.service:before,
.retail::before,
.androidtv::before {
  content: "";
  background-image: url(../images/Doodle1.webp);
  position: absolute;
  width: 20%;
  z-index: 5;
  top: 0%;
  left: -9%;
  right: 0%;
  height: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  /* overflow: hidden; */
  transform: rotate(0deg);
}

.service .title::after {
  content: "";
  background-image: url(../images/Doodle.webp);
  position: absolute;
  width: 20%;
  z-index: 5;
  height: 250px;
  background-size: contain;
  background-repeat: no-repeat;
  left: inherit;
  right: -20%;
  top: 300%;
}

.squarecont li span,
.title h2,
.title h3 {
  color: #000;
  letter-spacing: 0px;
  font-size: 55px;
  line-height: 60px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-position: 0;
  background-size: 100% 100%;
}

.bannercont h2 {
  font-size: 42px;
  /* margin-top: 15px; */

}

.containers label {
  margin: 0 30px;
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.footerform .form ul li:last-child,
.buttnprop {
  border: solid 1px #723ad6;
  width: 200px;
  height: 40px;
  display: flex;
  cursor: pointer;
}

.buttnprop span {
  background: #da1c38;
  top: -5px;
  left: -5px;
  font-size: 12px;
  letter-spacing: 2.6px;
  text-transform: uppercase;
  font-family: "Franklin Gothic Demi Cond";
  height: 100%;
}

.slick-dots li button:before {
  font-size: 10px;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: #b61d31;
}

.title span,
.bundlebox h4 {
  background: #e3e1e1;
  border-radius: 25px;
  font-size: 12px;
  letter-spacing: 4px;
  padding: 0 50px;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 15px;
}

.title h4 {
  font-size: 33px;
  color: #000;
  letter-spacing: 1px;
}

.servicecont h4 {
  font-size: 23px;
  color: #000;
  font-family: "Franklin Gothic Demi Cond";
  padding-bottom: 10px;
  border-bottom: solid 2px #000;
}

.slick-slider .slick-prev,
.planslider .ottslider .slick-slider .slick-prev {
  background-image: url(../images/left-arrow.webp);
}

.slick-slider .slick-next {
  background-image: url(../images/right-arrow.webp);
  background-position: right;
}

.blckarrow .slick-slider .slick-next {
  background-image: url(../images/right-arrow.webp) !important;
}

.blckarrow .slick-slider .slick-prev {
  background-image: url(../images/left-arrow.webp) !important;
}

.planslider .ottslider .slick-slider .slick-next {
  background-image: url(../images/right-arrow.webp);
}

.planslider .slick-slider .slick-prev {
  background-image: url(../images/white-left-arrow.webp);
}

.planslider .slick-slider .slick-next {
  background-image: url(../images/white-right-arrow.webp);
}

.number {
  background-image: url(../images/numbers.webp);
  background-size: cover;
  background-position: center;
}

.number.aboutnetplus {
  background-image: url(../images/snapshot-broadband.webp);
  height: auto;
  background-attachment: fixed;
}

.banner,
.number {
  height: 94vh;
}

.banner {
  background-size: cover;
  background-position: bottom;
}

.slick-slider .slick-next,
.slick-slider .slick-prev {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
}

.heading h4 {
  text-transform: uppercase;
  font-size: 29px;
  line-height: 40px;
  letter-spacing: 2px;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.9);
}

.heading h4:before {
  content: '';
  width: 50px;
  height: 2px;
  background: #d51e3f;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.business,
.plans {
  background-image: url(../images/plans-banner.webp);
  border-radius: 0 0 0 500px;
  background-attachment: fixed;
}

.plans.smarthome {
  background-image: url(../images/home-automation-banner.webp);
}

.business {
  background-image: url(../images/business-banner.webp);
}

.react-tabs__tab-list li {
  margin: 0 10px;
  background: #535355;
  padding: 0px 40px;
  border-radius: 25px;
  line-height: 55px;
  font-size: 13px;
  letter-spacing: 4px;
  outline: 0;
  text-transform: uppercase;
}

.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
  background: #d6223a;
}

.plansitem,
.featurestn,
.technology ul {
  background: #fff;
  border-radius: 25px;
}

.ptag {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  margin-top: -50px;
  text-align: center;
}

.ptag h5 {
  font-size: 27px;
  line-height: 30px;
  margin: 0;
  font-family: "Franklin Gothic Demi Cond";
  letter-spacing: 1px;
  text-transform: capitalize;
}

.pline {
  margin: 25px 0;
}

.pline ul li {
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
}

.pline ul li span:nth-child(1):before {
  content: "\2713";
  position: absolute;
  left: -60%;
  color: #378d00;
  font-weight: 900;
  font-size: 22px;
}

.price h6 {
  font-size: 42px;
  margin: 0;
}

.pricestn h3 strong,
.price h6,
.countnum p,
.androidtv .bundlebox h4,
.netplusadvantages h4,
.heading h4,
.featureInfo,
.featurebox h4,
.packstn .squarecont ul li ul li strong,
.squarecont li span,
.title h2,
.title h3,
.title span,
.bundlebox h4,
.title h4,
.listpoint ul li strong {
  font-weight: normal;
}

.price h6:before {
  content: "\20B9";
  font-family: 'Franklin Gothic Book';
}

.sprice,
.jabcont h3,
.enterprise .title,
.partnerbox .heading,
.booknow.filter .footerform .form ul li:nth-child(9),
.aboutnetplus .title,
.antivirus .ptag,
.contentpage ul,
.plansitem .price {
  margin-bottom: 25px;
}

.pline ul li:first-child,
.bundlebox .entertainment,
.bundlebox .entertainment ul,
.plans.smarthome.aboutnetplus .App,
.plans.smarthome.aboutnetplus .count {
  margin-top: 0;
}

.mrp h6:after {
  content: "\002B";
  font-weight: 200;
  font-size: 100px;
  position: absolute;
  left: 55%;
  transform: translate(-50%, -50%) rotate(45deg);
  top: 50%;
  color: #ef0023;
}

.sbanner,
.apprgt {
  width: 70%;
  padding: 50px;
  padding-bottom: 0;
  border-radius: 50px;
  right: 5%;
  background-image: linear-gradient(-120deg, #e7ceeb, #fed0d3);
}

.app:after {
  content: "";
  background-image: url(../images/Doodle.webp);
  position: absolute;
  width: 10%;
  z-index: 5;
  top: -3%;
  right: 0;
  left: -5%;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
}

.appleft {
  width: 30%;
  left: 5%;
  background: rgb(243 243 243 / 90%);
  padding: 50px 80px;
  z-index: 2;
}

.appbox h3,
.solutioncont h4 {
  font-size: 39px;
  line-height: 40px;
  font-weight: 600;
}

.appcta span {
  background: #d12341;
  line-height: 44px;
  margin-right: 15px;
  text-transform: uppercase;
  border-radius: 25px;
}

.appcta span a {
  color: #fff;
  position: relative;
  display: inline-block;
  padding: 0 25px;
}

.featurecont {
  width: 50%;
  padding: 50px;
  border-radius: 50px;
  background-image: linear-gradient(-120deg, #e7ceeb, #fed0d3);
}

.androidbox::after,
.androidtvrow::after,
.aboutus::after,
.feature:after,
.broadband::after,
.hdbundle::after {
  content: "";
  background-image: url(../images/Doodle.webp);
  position: absolute;
  width: 200px;
  height: 200px;
  z-index: 5;
  top: 0;
  left: 95%;
  right: 0%;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  transform: rotate(0deg);
}

.aboutus:after {
  top: inherit;
  bottom: 0;
}

.feature:before {
  content: "";
  background-image: url(../images/Doodle.webp);
  position: absolute;
  width: 10%;
  z-index: 5;
  top: 35%;
  right: 0;
  left: 0%;
  height: 15%;
  background-size: contain;
  background-repeat: no-repeat;
}

.featurerow,
.jabtout,
.prize,
.accordion .accordion-item .title,
.accortab ul li,
.booknow.filter .footerform .form ul,
.highlights ul,
.solutionrow {
  justify-content: space-between;
}

.featurebox span {
  position: absolute;
  left: 0;
  font-size: 160px;
  color: rgb(0 0 0 / 10%);
  font-family: "Franklin Gothic Demi";
  line-height: 65px;
}

.featurebox h4,
.packstn .squarecont ul li ul li strong {
  font-size: 25px;
  font-family: "Franklin Gothic Demi";
  color: #000;
  text-align: left;
}

.featureInfo {
  text-align: left;
  font-size: 25px;
  width: 80%;
  margin: 0px auto;
}

.featureInfo>p,
.slick-dots li,
.advantagecont p,
.hemburgermenus .sitemapcol,
.packs .planbanner,
.booknow.filter .footerform .form ul li:last-child,
.androidbox ul li p,
.title h3,
.advantagecont {
  margin: 0;
}

.countnum,
.countnum h3 {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  font-family: Playfair Display, serif;
  font-size: 50px;
  font-weight: 400;
  justify-content: center;
  line-height: 60px;
  margin: 0;
}

.androidtv .bundlebox h4,
.androidbox ul li p,
.highlights ul li span {
  margin: 0;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 1px;
}

.App,
.medleft,
.circleicons ul li {
  width: 22%;
}

.technology ul li {
  width: 20%;
  text-align: center;
  margin: 0 2%;
}

.aboutimg {
  width: 55%;
}

.aboutus:before {
  content: "";
  background-image: url(../images/Doodle.webp);
  position: absolute;
  width: 15%;
  z-index: 5;
  top: 20%;
  right: 0;
  left: -5%;
  height: 30%;
  background-size: contain;
  background-repeat: no-repeat;
}

.technologystn:after {
  content: "";
  background-image: url(../images/Doodle.webp);
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 50%;
  left: 95%;
  right: 0%;
  height: 60%;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  transform: rotate(0deg);
}

.jabcont,
.appleft.form,
.feartureimg,
.slidecont {
  width: 40%;
}

.booknow.filter .footerform .form ul li {
  width: 45%;
}

.jabcont .title {
  text-align: left;
  margin: 0;
}

.aboutimg img {
  border-radius: 150px 0 0 0;
  width: 100%;
}

.footerform .form ul li:last-child:hover {
  border-color: #292a2b;
}
.ul1{
  color: white;
}
.circle01,
.hungamacircle {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: #d51e3f;
  padding: 75px;
}

.pricestn h3,
.price h6 small {
  margin-bottom: 0;
  font-size: 21px;
}

.pricestn ul,
.bannercont,
.lg-react-element a {
  width: 30%;
}

.listpoint ul li strong,
.pricestn li strong,
.andoidcont h4,
.bundlebox ul li strong,
.pricestn h3 strong {
  font-size: 32px;
  line-height: 40px;
}

.pricestn li {
  font-size: 28px;
  line-height: 28px;
  overflow: hidden;
}

.circle02 {
  width: 320px;
  height: 320px;
  position: absolute;
  top: -20%;
  border-radius: 50%;
  background: rgb(180 90 169 / 70%);
  z-index: 1;
  left: -10%;
}

.circle02 h2,
.plancont h4 {
  margin: 0;
  font-size: 40px;
  line-height: 40px;
}

.circlimg,
.listpoint,
.slidecont {
  z-index: 2;
}

.slideimg img {
  width: 95%;
  left: 10%;
  z-index: 3;
}

.sliderrow,
.androidfeature,
.androidtvrow {
  margin-top: 80px;
}

.banner.mngslide .sliderrow,
.slick-track .slick-slide:nth-child(even) .sliderrow {
  width: 85%;
  float: right;
}

.banner.mngslide .sliderrow .slideimg img,
.slick-track .slick-slide:nth-child(even) .sliderrow .slideimg img {
  left: 0;
}

.slideimg,
.solutioncont p {
  width: 60%;
}

.slick-track .slick-slide:nth-child(even) .sliderrow .circle02 {
  left: inherit;
  right: 0%;
}

.finr:before {
  content: "\20B9";
  font-family: "Franklin Gothic Book";
  font-style: normal;
}

.circlimg h2 {
  font-size: 40px;
  line-height: 40px;
}

.listpoint ul li,
.bannercont ul li,
.hungamacont ul li {
  font-size: 20px;
  line-height: 20px;
}

.listpoint ul,
.hungamacont ul,
.contentpage ul {
  list-style: disc;
  padding-left: 25px;
}

.booknow a {
  font-size: 14px;
  letter-spacing: 3px;
  border: solid 1px #efefef;
  line-height: 40px;
  padding: 0px 20px;
  border-radius: 25px;
  text-transform: uppercase;
}

.slick-dots {
  position: absolute;
  bottom: 5%;
}

.price ul li,
.coreadvantages .squarecont ul li ul li,
.hungamacont ul li {
  padding: 5px 0;
}

.price ul li:before {
  content: '';
  width: 20px;
  position: absolute;
  left: 50%;
  height: 2px;
  bottom: 0;
  transform: translate(-50%);
}

.entertainment ul {
  display: flex;
  width: max-content;
}

.entertainment ul li {
  padding: 7px;
}

.form li {
  margin-bottom: 25px;
}

.featurerow:last-child {
  margin-bottom: 0;
}

.pricestn li:first-child,
.gradienticons ul li span,
.plancont h4 {
  margin-bottom: 5px;
}

.bannercont {
  position: absolute;
  top: 71%;
  right: 9%;
  /* font-weight: bolder;
  color: rebeccapurple; */

}

.title p,
.androidbox7,
.androidtvrow3,
.andoidcont {
  width: 50%;
}

main #banner:after {
  top: 66%;
}

main .navbar ul li a,
.coreadvantages .squarecont li p,
.navbar .sitemapcol h4 a,
.fixed .navbar .sitemapcol h4 a,
.booknow a,
.plancont,
.coreadvantages .squarecont ul li ul li,
.plans.smarthome .circleicons ul li,
.pack h5,
.bundlebox h4,
.packstn .squarecont li strong,
.packstn .squarecont ul li ul li strong,
.buttnprop span,
.react-tabs__tab-list li,
.ptag h5,
.appcta span,
.countnum span,
.countnum,
.circle01,
.pricestn li,
.circle02 h2,
.plancont h4,
/* .bannercont h2, */
.androidbox ul li p,
.countnum p,
.circlimg h2,
.listpoint p,
.pack h5 strong,
.pack h5 strong i,
.booknow a,
.plancont p,
.image_plans_div p span,
.bundlebox h4 i,
.highlights ul li span,
.pricestn li strong,
.pricestn h3,
.pricestn h3 strong,
.pricestn h3 strong span,
.bundlebox h4 strong,
.circlimg h2 span,
.listpoint ul li,
.listpoint ul li strong,
.listpoint ul li strong i,
.freepacks li strong,
.btechnoimg h4,
.business p,
.btechnocont li,
.faqcont table tr th {
  color: #fff;
}

.bannercont h2{
  color: hsl(325, 68%, 46%);
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin-left: 20%;

}



.advantages,
.squarecont ul,
.circleicons ul,
.androidfeature ul,
.bundlestn {
  display: flex;
  justify-content: center;
  gap: 50px;
  text-align: center;
}

.advantageicon span,
.circleicons ul li span {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  box-shadow: 1px 1px 11px 1px rgb(85 85 85 / 18%);
  margin-bottom: 25px;
}

.btechnoimg h4,
.technocont h4,
.circleicons ul li h5 {
  font-family: "Franklin Gothic Demi Cond";
  font-size: 28px;
  line-height: 30px;
  text-transform: capitalize;
  margin: 0;
}

.advantagesbox,
.bundlebox,
.plans.smarthome .count .App,
.planbanner,
.partnerbox {
  margin: 50px 0;
}

.filter .form li select {
  height: 50px;
  border: none;
}

.form li select {
  width: 100%;
  border: none;
  border-bottom: solid 2px #000;
}

.appleft.form .buttnprop {
  justify-content: end;
  float: right;
}

.appleft.form {
  border-radius: 0 85px 0px 0;
}

.squarecont li {
  padding: 40px;
  width: 24%;
  text-align: left;
  border-radius: 25px;
}

.squarecont li span {
  font-size: 36px;
  line-height: 40px;
  font-family: "Franklin Gothic Demi Cond";
  text-align: left;
}

.squarecont li strong {
  font-size: 74px;
  line-height: 80px;
}

.squarecont ul,
.solutiontech ul {
  gap: 10px;
}

.bundlestn,
.advantages,
.bundlebox .entertainment ul,
.hungamabox,
.lg-react-element,
.protechnology,
.packmodule {
  gap: 25px;
}

.accordion .accordion-item:first-child .title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion .accordion-item:last-child .title {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion .accordion-item .title {
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  background-color: #fff;
  display: flex;
  margin: 0;
  float: left;
  padding: 15px 20px;
  border-radius: 35px !important;
}

.accordion .accordion-item .title:after {
  color: #000;
  content: "\002B";
  float: right;
  font-size: 32px;
  font-weight: 300;
  margin: 0;
}

.accordion .accordion-item .panel {
  max-height: 0;
  -webkit-transition: max-height 0.15s ease-out;
  -o-transition: max-height 0.15s ease-out;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.accordion .accordion-item.active .title:after {
  content: "\2212";
}

.accordion .accordion-item.active .panel {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
  width: 100%;
  padding: 25px;
}

.calctab .accordion-item button {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.accortab ul {
  flex-wrap: wrap;
  justify-content: start;
}

.accortab ul li {
  margin: 5px 0;
  width: 100%;
  float: left;
  display: flex;
}

.accortab,
.circleicons ul li,
.gradienticons ul,
.advantagecont {
  margin: 10px 0;
  align-items: baseline;
}

.accordion-item {
  margin: 5px 0;
  border: none;
  background-color: transparent;
}

.plans.smarthome .circleicons ul li,
.pack h5,
.highlights ul li,
.squarecont li span {
  display: grid;
}

.coreadvantages .squarecont li span {
  -webkit-text-fill-color: #fff;
  padding-right: 40px;
  line-height: 36px;
  margin-bottom: 10px;
  font-size: 28px;
}

.smartcont,
.circleicons ul li p,
.booknow.filter .footerform,
.title h2,
.title h3,
.ftrbox svg {
  width: 70%;
  margin: 0px auto;
  clear: both;
}

.advantageicon span,
.advantagecont {
  border: none;
  box-shadow: inherit;
}

.advantagecont {
  padding: 35px;
  border-radius: 25px;
  font-size: 21px;
  text-align: left;
  height: 220px;
}

.advantagesbox span {
  margin: -20px;
}

.plans.smarthome .count .App {
  width: 30%;
}

.plans.smarthome.aboutnetplus .App {
  width: 20%;
}

.advantagesbox,
.business .technobox {
  width: 18%;
}

.netplusadvantages h4,
.androidbox ul li p {
  font-size: 25px;
  line-height: 30px;
}

.advantagestn h4 {
  font-size: 33px;
  line-height: 40px;
  font-family: "Franklin Gothic Demi";
  margin: 0;
}

.advantagestn li {
  margin: 25px 0px;
}

.pack h5 {
  margin: 0;
  font-size: 25px;
  line-height: 30px;
}

.pack h5 strong {
  font-size: 34px;
  line-height: 40px;
  font-weight: normal;
  display: flex;
}

.login.contactpage .footerform .form ul li input,
.filter .footerform .form ul li input,
.filter .footerform .form ul li,
.filter .footerform .form ul li input::placeholder,
.booknow a:hover,
.contactpage .enquireform2 .footerform .form ul li input,
.contactpage .enquireform2 .footerform .form ul li input::placeholder,
.contactpage .enquireform2 .footerform .form ul li,
.login.contactpage .footerform .form ul li,
.booknow.filter .footerform .form ul li textarea::placeholder {
  color: #000;
  border-color: #000;
}

.login.contactpage .footerform .form ul li input::placeholder,
.contactpage .enquireform2 .form h2 {
  color: #000;
}

.gradienticons ul li span {
  width: 120px;
  height: 120px;
  padding: 5px;
  border-radius: 25px;
}

.gradienticons ul li p,
.packstn .squarecont li,
.packstn .squarecont li span,
.containers label,
.androidbox ul li p {
  font-family: Poppins, sans-serif;
}

.feartureimg,
.plancont,
.coreadvantages .squarecont ul li ul li,
.gradienticons ul li p,
.packstn .squarecont li,
.packstn .squarecont li span,
.smartcont,
.circleicons ul li p,
.androidbox ul li {
  text-align: center;
}

.androidtvrow:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  right: -30%;
  border-radius: 300px 0px 0 300px;
}

.androidfeature ul li h4 {
  font-size: 24px;
  line-height: 30px;
  font-family: 'Franklin Gothic Demi Cond';
}

.planbanner {
  background-image: url(../images/android-tv-banner.webp);
  height: 65vh;
  background-size: cover;
  background-attachment: fixed;
}

.packs .planbanner {
  background-image: url(../images/netplus-bundle.webp);
}

.planbanner {
  border-radius: 200px 200px 0px 0px;
}

.androidtvrow:nth-child(even)::before {
  right: inherit;
  left: -30%;
  border-radius: 0px 300px 300px 0px;
}


.bundlebox {
  padding: 50px;
  width: 30%;
  box-shadow: 1px 1px 5px 1px rgb(85 85 85 / 18%);
  border-radius: 15px;
}

.bundlebox h4 {
  background: #6d6e71;
  text-align: center;
  padding: 0px 40px;
  position: absolute;
  top: 0%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  border-radius: 50px;
  letter-spacing: 1px;
}

.packstn .squarecont ul li strong,
.bundlebox h4 {
  font-size: 36px;
}

.bundlebox ul li {
  padding: 15px 0;
  font-size: 21px;
}

.bundlebox ul li:before,
.coreadvantages .squarecont ul li ul li::before {
  content: '';
  width: 60px;
  height: 1px;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -50%);
  left: 50%;
}

.bundlebox ul li:before,
.coreadvantages .squarecont ul li ul li::before,
.price ul li:before {
  background: #000;
}

.bundlebox ul {
  margin: 40px 0;
}

.bundlebox .buttnprop {
  justify-content: center;
  margin: 0px auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bundlebox ul li:last-child:before,
.coreadvantages .squarecont ul li ul li,
.plans.smarthome.aboutnetplus,
.form li select {
  background: transparent;
}

.bundlebox ul li:last-child {
  padding-bottom: 0;
}

.androidfeature ul li span,
.coreadvantages .squarecont ul li ul li,
.packstn .squarecont li span {
  display: inline-block;
}

.androidfeature ul li span img:last-child {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0;
}

.hdbundle::before,
.androidbox::after,
.androidtv::before,
.aboutus:after,
.enterprise::before,
.broadband::before,
.service:before,
.service .title::after,
.app:after,
.feature:after,
.feature:before,
.technologystn:after,
.aboutus:before,
.retail::before,
.broadband::after,
.squareshape::before,
#banner:after,
.partner::before,
.androidtvrow::after,
.hdbundle::after {
  -webkit-animation-duration: 240s;
  animation-duration: 240s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes turn-7 {
  to {
    transform: rotate(0.500urn);
  }

  from {
    transform: rotate(0);
  }
}

@keyframes turn-7 {
  to {
    transform: rotate(0.875urn);
  }

  from {
    transform: rotate(0);
  }
}

.circleicons ul li p {
  line-height: 22px;
}

.hdbundle::before,
.squareshape::before,
.enterprise::before,
.partner::before,
.androidtv::before,
.androidtvrow::after,
.androidbox::after {
  width: 320px;
  height: 320px;
}

.accordiancont ul li,
.contentpage ul li {
  padding: 2px 0;
  list-style: square;
}

.accordiancont ul,
.bannercont ul {
  padding-left: 25px;
}

.bannercont ul {
  list-style: disc;
}

.bannercont ul li {
  margin: 0 20px;
}

.technology ul li img {
  filter: grayscale(1);
}

.technology ul li:hover img {
  filter: grayscale(0);
}

/* #retail,
#homeautomation,
#enterprise,
#smartcity {
  padding-top: 0;
  margin-top: -70px;
} */

section {
  overflow: hidden;
}

.androidtvrow::after {
  right: -35%;
  left: inherit;
  top: 35%;
}

.androidtvrow:nth-child(even)::after {
  right: inherit;
  left: -35%;
}


.btechnoimg,
.androidbox ul li,
.bundlebox h4,
.hemburgermenus,
.gradienticons ul li span,
.coreadvantages .squarecont li,
.ptag,
.enquireform2,
.gradient {
  background: linear-gradient(120deg, rgba(106, 61, 226, 1), rgba(175, 44, 126, 1) 50%, rgba(236, 48, 71, 1));

}

.bundlebox ul li strong {
  font-family: "Franklin Gothic Demi Cond";
  font-weight: normal;
}

.packstn .squarecont li strong,
.packstn .squarecont ul li ul li strong {
  line-height: inherit;
}

.circleicons ul li span,
.coreadvantages .squarecont ul li ul li::before {
  background: #fff;
}

.coreadvantages .squarecont ul li ul li::before {
  width: 30px;
}

.coreadvantages .squarecont ul li ul li:last-child:before,
.price ul li:last-child:before,
.bundlebox .entertainment ul li:before {
  opacity: 0;
}


.booknow.filter .footerform .form ul li:nth-child(2),
.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop {
  border: none;
}

.booknow a,
.buttnprop span,
.technology ul li img {
  transition: ease all 0.5s;
}

.androidtv .androidtv {
  position: static;
}

.androidtv .androidtv:before,
.hdbundle::after {
  top: 50%;
  content: '';
  bottom: inherit;
}

.packstn .squarecont li span,
.bundlebox .entertainment li,
.accortab ul li {
  padding: 0;
}

.highlights {
  border-top: solid 1px #ededed;
  padding-top: 50px;
  margin-top: 50px;
}

.lefttextalign {
  right: inherit;
  bottom: 50%;
  top: inherit;
  left: 20%;
}

.contactpage .enquireform2 {
  background: transparent;
}

.bundlebox h4 i {
  margin-right: 5px;
}

.h-120 {
  height: 120px;
}

.banner.h-120:before {
  height: 120px;
}

.navbar ul li .dropdown .sitemapcol .active:before {
  background: none;
}

.countnum p {
  text-transform: uppercase;
  font-size: 19px;
  line-height: 27px;
  margin: 0 5px;
}

.labelcont p {
  font-family: 'Franklin Gothic Book';
  font-size: 17px;
  letter-spacing: 3px;
  line-height: 20px;
  text-transform: uppercase;
  width: 90%;
  margin: 0px auto;
  margin-top: 5px;
  text-align: center;
}

.highlights ul li {
  color: #d12341;
  font-family: 'Franklin Gothic Demi Cond';
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 32px;
  text-align: center;
  width: 25%;
}

.App:before {
  background: #d12341;
  content: "";
  height: 100%;
  left: 5%;
  position: absolute;
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
  width: 2px;
}

.partnerbox ul li {
  width: 14%;
  margin: 0 1%;
}

.banner.mngslide {
  height: 90vh;
}

.squarecont li,
.advantageicon span,
.advantagecont {
  background: #eaeaea;
}

.androidbox ul li p {
  letter-spacing: 0.5px;
  font-family: "Franklin Gothic Book";
  padding: 0 10px;
  margin-top: 10px;
}

.androidbox ul li p:last-child {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  padding: 0;
}

/* popup  */
.popup {
  position: fixed;
  background: rgb(0 0 0 / 90%);
  z-index: 99;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: ease all 0.5s;
}

.popup.active {
  opacity: 0;
  visibility: hidden;
}

.popimg {
  width: 50%;
  position: relative;
}

.popimg img,
.jabtout .title h3 {
  width: 100%;
}

.popdesign {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border: none;
  background: transparent;
  color: #000;
  font-weight: 200;
  font-family: Poppins, sans-serif;
}

.price.ottprice {
  padding-top: 30px;
  margin-top: 30px;
  border-top: solid 1px #ededed;
}

.price.ottprice small {
  font-size: 16px;
}

.packstn li {
  background-image: url(../images/gradient-bg.webp) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.booknow.filter .footerform.bookingform .form ul li:nth-child(2) {
  border-bottom: 2px #000 solid;
}

.booknow.filter .footerform .form ul li textarea {
  border: none;
  resize: none;
  width: 100%;
  padding: 0;
}

.ottslider .slick-slider .slick-prev,
.ottslider .slick-slider .slick-next {
  width: 25px;
  height: 25px;
}

.plansitem {
  box-shadow: 1px 1px 11px 1px rgb(85 85 85 / 18%);
  margin-bottom: 50px;
}

.navbar ul li:nth-child(7),
.navbar ul li:nth-child(6),
.react-tabs__tab-list li,
.accordion .accordion-item .title {
  cursor: pointer;
}

.accordiancont input[type=text] {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000;
  letter-spacing: .5px;
  width: 250px;
  line-height: 40px;
}

.accordiancont input[type="text"]::placeholder {
  font-size: 15px;
}

.packstn .squarecont ul li strong {
  line-height: inherit;
}

.bundlebox ul li strike {
  color: #d6223a;
}

.antiviruspacks .slick-list .bundlebox,
.hungamafrow {
  width: 90%;
  margin: 50px 5%;
}

.jujharnumber {
  background-image: url(../images/number-banner.webp);
  background-attachment: fixed;
  background-position: 50%;
  background-size: cover;
  padding: 100px 0;
}

.contentpage h5 {
  margin: 25px 0 10px 0;
  background: #d5d5d5;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 1.5px;
}

.navbar ul li a svg {
  width: 20px;
  margin-right: 5px;
}

.medleft ul {
  background: #e3e1e1;
  float: left;
  padding: 25px;
  width: 100%;
}

.medleft ul li {
  border-bottom: 1px solid #a1a1a2;
  float: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.medleft ul li a {
  color: #000;
  display: inline-block;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-weight: 600;
}

.years ul li {
  margin-right: 30px;
}

.years ul li a {
  color: #000;
  text-decoration: none;
}

.years ul li a.tab--active {
  background: #d12341;
  border-radius: 5px;
  color: #fff;
  display: flex;
  padding: 0 10px;
}

.medright .react-tabs ul li {
  border-right: 1px solid #e1e1e1;
  cursor: pointer;
  float: left;
  line-height: 15px;
  position: relative;
  width: 8.3%;
}

.years {
  margin-bottom: 15px;
}

.medright .react-tabs ul li:last-child,
.medleft ul li:last-child {
  border: none;
}

.eventsbox {
  float: left;
  margin: 0 2%;
  width: 46%;
}

.eventsbox a {
  float: left;
  margin-bottom: 25px;
  width: 100%;
}

.eventsbox h5 {
  color: #000;
  float: left;
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.eventsbox a img,
.lg-react-element a img {
  height: 250px;
  object-fit: cover;
  width: 100%;
}

.medright .react-tabs ul li.react-tabs__tab--selected,
.medleft ul li a.active {
  color: #cd2742;
}

.gallery .buttnprop span {
  background: transparent !important;
  color: #000 !important;
  top: 0;
  font-family: Poppins, sans-serif;
  letter-spacing: 1px;
}

.gallery .buttnprop {
  border: none;
  height: auto;
  width: max-content;
  margin: 0;
  position: relative;
}

.gallery .buttnprop span:before {
  content: '';
  background-image: url(../images/left-arrow.webp);
  position: absolute;
  left: -13px;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  background-size: contain;
  top: 6px;
}

.jabtout .title h3 {
  margin-bottom: 10px;
}

.booknow a:hover {
  background: #000;
  color: #fff;
}

.androidbox ul li img {
  transform: scale(1);
  transition: ease all 0.5s;
}

.androidbox ul li:hover img {
  transform: scale(1.1);
}

.options .bundlebox a {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.booknow.filter .footerform .form ul li:nth-child(9) {
  margin: 0px auto;
}

.accordiancont p span {
  background: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  margin-top: 10px;
  width: 250px;
  font-size: 14px;
  justify-content: center;
  color: #000;
}

.ottslider .slick-slider .slick-prev,
.ottslider .slick-slider .slick-next {
  background-position: center;
}

.slick-prev {
  left: -35px;
}

.slick-next {
  right: -35px;
}

.ottslider .slick-next {
  right: -25px;
}

.ottslider .slick-prev {
  left: -25px;
}

.technologystn .title h3,
.solutiontech .title h3 {
  line-height: 70px;
}

.listpoint ul li strong {
  line-height: 0;
}

.androidtv .bundlebox h4,
.bannercont h2,
.title h4,
.title span,
.bundlebox h4,
.price h6,
.packstn .squarecont ul li strong,
.bundlebox h4 {
  line-height: 50px;
}

.sbanner,
.btechnoimg {
  padding: 50px 0;
  width: 100%;
}

.navbar ul li a.desktop-show,
.desktop-show,
.sliderrow.slide05 img.mobile-show {
  display: block;
}

.navbar ul li a.mobile-show,
.mobile-show,
.sliderrow.slide05 img.mobile-show {
  display: none;
}

.hbanner:after {
  bottom: inherit !important;
  top: 12%;
}

.apps {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 25px;
}

.apps img {
  width: 30%;
}

.androidtvrow3 img {
  width: 80%;
}

.smartcont a {
  color: #0a58ca;
  text-decoration: underline;
}

.hdbundle .title h3 {
  line-height: initial;
}

.disabled {
  background-color: #a0a2a4 !important;
  color: #696b6f !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.hideMsg {
  display: none !important;
}

.error {
  /* background: #ffcccc !important; */
  color: #990000 !important;
}

.cntNumbers {
  flex-wrap: wrap;
  row-gap: 3em;
}

.freepacks li span {
  background: #fff;
  font-size: 11px;
  letter-spacing: 3px;
  font-weight: bold;
  padding: 5px 20px;
  border-radius: 15px;
}

.freepacks li {
  font-family: Poppins, sans-serif;
  display: inline-block;
}

.freepacks li strong {
  font-size: 90px;
  line-height: 60px;
  float: left;
  width: 100%;
  padding: 15px 0 0 0;
  display: flex;
  align-items: baseline;
}

.freepacks li strong small {
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  letter-spacing: 2px;
  white-space: nowrap;
  line-height: initial;
  margin-left: 8px;
}

ul.freepacks {
  position: relative;
  z-index: 2;
}

.freepacks {
  margin: 0 1%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.form li span {
  white-space: nowrap;
  padding-right: 10px;
}

.contactoverview {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #ededed;
  margin-bottom: 50px;
}

.contactbox {
  width: 25%;
  margin: 0 5%;
}

.contactbox h3 {
  border-left: solid 5px #d12341;
  padding-left: 10px;
  font-size: 26px;
  letter-spacing: 2px;
  line-height: 32px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.contactbox li p {
  display: grid;
}

.contactbox li {
  margin: 10px 0;
  border-bottom: solid 1px #ededed;
  padding: 10px 0;
}

.contactbox li span {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  margin-bottom: 5px;
  display: inline-block;
}

.contactbox li:last-child {
  border: none;
}

.contactoverview:last-child {
  border: none;
  margin: 0;
}

.office {
  width: 350px;
  height: 300px;
  overflow: auto;
  padding-right: 35px;
}

.office::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.office::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.office::-webkit-scrollbar-thumb {
  background-color: #382323;
  border: 1px solid #555555;
}

.contactbox .officebox ul li {
  margin: 0;
}

.contactbox .officebox ul li span a {
  border-bottom: solid 1px #070707;
  transition: ease all 0.5s;
  padding-bottom: 5px;
}

.contactbox .officebox ul li span:hover a {
  border-bottom: solid 2px #d12341;
  color: #d12341;
}

.officebox {
  margin-bottom: 35px;
}

.officebox h4 {
  font-size: 26px;
  font-family: "Franklin Gothic Demi Cond";
  letter-spacing: 1px;
  line-height: 30px;
  
}

.technobox {
  width: 20%;
}

.technocont h4,
.btechnoimg h4 {
  margin: 10px 0;
  display: inline-block;
}

.technocont ul li {
  padding: 1px 0;
  margin: 1px 0;
  position: relative;
}

/* .technocont ul li:before {
  content: '';
  width: 40px;
  height: 1px;
  background: #2f2f2f;
  position: absolute;
  bottom: 0;
} */

.technoimg img {
  border-radius: 40px 40px 0px 0px;
}

.protechnology {
  align-items: flex-start;
}

.btechnoimg {
  text-align: center;
  margin-bottom: 10px;
  padding: 25px 0;
}

.technocont ul li,
.btechnocont li {
  margin: 8px 0;
  padding: 8px 0;
  position: relative;
  padding-top: 0;
}

.technocont ul li::before,
.btechnocont li:before {
  content: '';
  width: 30px;
  height: 1px;
  background: #000;
  position: absolute;
  bottom: 0;
}

.circleicons ul li span svg {
  width: auto;
}

.booknow .heading {
  width: 70%;
  margin: auto;
  float: none;
  margin-bottom: 50px;
}

.freepacks:nth-child(1) {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
}

.freepacks:nth-child(1) li span {
  background: #d12341;
  color: #fff;
}

.freepacks:nth-child(1) li small,
.freepacks:nth-child(1) li strong {
  color: #d12341;
}

.btechnocont li:before {
  background-color: #fff;
}

.btechnoimg img {
  width: 65px;
}

.faqcont .table td {
  vertical-align: middle;
}

.faqcont table.table.table-striped tr.gradient th {
  color: #fff;
  text-transform: uppercase;
  font-family: "Franklin Gothic Demi Cond";
  letter-spacing: 2px;
}

.circle01.align-self-end {
  align-items: self-end;
}

.circle01.align-self-end .mngcircle {
  position: relative;
  top: 2rem;
}

/* 
.listpoint h5 {
  background: #fff;
  display: inline-block;
  font-size: 30px;
  width: 90%;
  text-align: center;
  line-height: 50px;
  border-radius: 35px;
} */

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.navbar ul li {
  padding: 0 15px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
}

.navbar ul li:nth-child(6),
.navbar ul li:nth-child(7) {
  padding: 5px 20px;
  margin: 0 5px;
}

.slide05 .listpoint ul li {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 5px 0;
}

.slide05 .slidecont {
  width: 55%;
  align-items: center;
  display: flex;
}

.slide05 .slideimg {
  width: auto;
}

.slide05 .slideimg img {
  width: auto;
}

.slide05 .listpoint ul li::marker {
  font-size: 100%;
}

.listpoint h5 {
  background: #d51e40;
  display: inline-block;
  font-size: 38px;
  text-align: center;
  line-height: 56px;
  border-radius: 35px;
  padding: 0 30px;
  color: #fff;
}

.slide05 .finr:before {
  color: #fff;
}

.slide05 .booknow a {
  border: 1px solid #000;
  color: #000;
}

.slide05 .booknow a:hover {
  color: #fff;
}

.slide05 {
  margin-top: 0;
}

.gap_4 {
  gap: 20px;
}

.show_980 {
  display: none !important;
}


.Image_loader {
  width: 300px;
  height: 270px;
  position: relative;
  background: white;
}

.Image_loader::after {
  content: '';
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(90deg, transparent, rgba(238, 238, 238, 0.5) 50%, transparent 100%),
    linear-gradient(#DDD 74px, transparent 0),
    linear-gradient(#DDD 16px, transparent 0),
    linear-gradient(#DDD 24px, transparent 0);
  background-repeat: no-repeat;
  background-size: 50px 170px, 100% 74px, 100% 16px, 100% 32px;
  background-position: 0 0, 0 0, 0px 85px, 0px 110px;
  animation: animloader126 1s linear infinite;
}

@keyframes animloader126 {
  0% {
    background-position: 0 0, 0 0, 0px 85px, 0px 110px;
  }

  100% {
    background-position: 360px 0, 0 0, 0px 85px, 0px 110px;
  }
}

/* Style for the modal content */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
}

/* Style for the modal overlay */
.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Style for the close button */
button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
