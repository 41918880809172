@media all and (max-width:1740px) {
    .logo {
        width: 150px;
        margin-left: 2%;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop {
        padding: 0 10px;
    }

    .jujhar {
        width: 125px;
        height: 50px;
    }

    .appleft {
        padding: 50px;
    }

    .dropdown:before {
        left: 8.5%;
        width: 9.6rem;
        top: -5px;
    }

    .dropdown .logo {
        left: 6%;
    }

    .slick-dots {
        bottom: 0;
    }

    .androidbox ul li p {
        font-size: 21px;
        line-height: 26px;
    }

    .hdbundle::before,
    .squareshape::before,
    .enterprise::before,
    .partner::before,
    .androidtv::before,
    .androidtvrow::after,
    .androidbox::after {
        width: 200px;
        height: 200px;
    }

    .navbar ul li {
        padding: 0 10px;
    }

    .navbar ul li:nth-child(6),
    .navbar ul li:nth-child(7) {
        padding: 8px 12px;
        font-size: 12px;
    }
}

@media all and (max-width:1600px) {
    .squarecont li strong {
        font-size: 60px;
        line-height: 60px;
    }

    .navbar ul li {
        letter-spacing: 1.5px;
    }

    .freepacks li span {
        font-size: 9px;
        letter-spacing: 2px;
    }

    .freepacks li strong {
        font-size: 80px;
        line-height: 50px;
    }

    .freepacks li strong small {
        font-size: 11px;
    }

    .banner:before {
        width: 180px;
        height: 180px;
    }

    .fixed .dropdown .logo {
        top: 20%;
        left: 0;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop {
        line-height: 30px;
        font-size: 11px;
        letter-spacing: 1.8px;
    }

    .ftrbox ul li {
        font-size: 15px;
        line-height: 24px;
    }

    .slideimg img {
        width: 88%;
    }

    .apprgt img {
        width: 70%;
        float: right;
    }

    .plansitem {
        width: 94%;
    }

    .featurebox h4,
    .featureInfo {
        font-size: 21px;
    }

    .sitemapcol h4 {
        font-size: 13px;
    }

    .dropdown .logo {
        left: 7%;
    }

    .circle01,
    .hungamacircle {
        width: 400px;
        height: 400px;
    }

    .circle02 {
        width: 280px;
        height: 280px;
        top: -30%;
    }

    .circle02 h2 {
        font-size: 32px;
        line-height: 32px;
    }

    .circlimg img {
        width: 75%;
        float: right;
    }

    .circle01 {
        padding: 60px;
        padding-right: 80px;
    }

    .pricestn li,
    .ptag h5 {
        font-size: 22px;
        line-height: 24px;
    }

    .pricestn h3 {
        font-size: 17px;
    }

    .pricestn h3 strong,
    .pricestn li strong,
    .listpoint ul li strong,
    .image_plans_div P,
    .advantagestn h4,
    .andoidcont h4 {
        font-size: 28px;
    }

    .pricestn h3 {
        margin-top: 15px;
    }

    .pricestn .booknow,
    .mngcircle .booknow {
        margin-top: 15px;
        margin-bottom: 0;
    }

    .booknow a {
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 35px;
    }

    .circlimg h2 {
        font-size: 30px;
        line-height: 30px;
    }

    .slide05 .listpoint ul li {
        font-size: 16px;
        line-height: 19px;
        padding-left: 0;
    }

    .listpoint ul li {
        font-size: 18px;
        line-height: 24px;
    }

    .title h2,
    .title h3 {
        font-size: 44px;
        line-height: 50px;
    }

    .title span,
    .bundlebox h4,
    .react-tabs__tab-list li {
        padding: 0 30px;
        letter-spacing: 3px;
    }

    .jabtout .butn {
        margin: 0;
    }

    .slick-dots {
        bottom: 5%;
    }

    body {
        font-size: 17px;
        line-height: 26px;
    }

    .technologystn .title h3 {
        line-height: 50px;
    }

    .advantageicon span,
    .circleicons ul li span {
        width: 150px;
        height: 150px;
    }

    .andoidcont h4 {
        line-height: 32px;
    }
}

@media all and (max-width:1440px) {

    .pricestn li:nth-child(1):before,
    .bannercont h2 {
        font-size: 40px;
    }

   

    .heading h4 {
        font-size: 26px;
        line-height: 32px;
    }

    .btechnoimg h4,
    .technocont h4,
    .circleicons ul li h5 {
        font-size: 24px;
        line-height: 30px;
    }

    .bundlebox ul li strong {
        font-size: 28px;
    }

    .appbox h3,
    .title h4,
    .squarecont li span,
    .pack h5 strong {
        font-size: 32px;
        line-height: 40px;
    }

    .appcta span {
        padding: 0 20px;
        font-size: 15px;
        line-height: 40px;
    }

    .featurebox span {
        font-size: 130px;
    }

    .form h2,
    .price h6 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 40px;
    }

    .hungamacont ul li {
        font-size: 17px;
        line-height: 22px;
    }

    .labelcont p {
        font-size: 14px;
        letter-spacing: 2px;
        margin: 0;
        width: 100%;
    }

    .slick-dots li button:before {
        font-size: 8px;
        width: 10px;
        height: 10px;
    }

    .netplusadvantages h4,
    .advantagecont,
    .pack h5,
    .bundlebox ul li {
        font-size: 19px;
        line-height: 24px;
    }

    .footerform .form ul li,
    .booknow.filter .footerform.bookingform .form ul li:nth-child(2) {
        border-width: 1px;
    }

    .logo {
        width: 125px;
        margin-left: 0;
    }

    .navbar ul li a svg {
        width: 17px;
    }

    .jujhar {
        width: 90px;
        height: 40px;
    }

    .jujhar svg {
        width: 90%;
    }

    .slideimg img,
    .servicecont p,
    .circleicons ul li p {
        width: 100%;
    }

    .jabtout {
        width: 90%;
        margin: 0 5%;
    }

    .banner:before {
        width: 150px;
        height: 150px;
    }

    .fixed .logo {
        width: 100px;
        margin-right: 5%;
    }

    .featurestn {
        width: 90%;
    }

    .hemburgermenus {
        width: 250px;
    }

    .navbar ul li {
        padding: 0 5px;
    }

    .navbar ul li:nth-child(7),
    .navbar ul li:nth-child(6) {
        padding: 5px 12px;
    }

    .hemburgermenus .dropdown {
        padding: 10px 30px;
        padding-bottom: 80px;
    }

    .squarecont li {
        padding: 25px;
    }

    .slick-dots {
        bottom: 3%;
    }

    section,
    .chooseplan,
    .plans,
    .faq .container,
    .androidtvrow,
    .number.aboutnetplus .container {
        padding: 50px 0;
    }

    .slick-slider .slick-next,
    .slick-slider .slick-prev {
        width: 45px;
        height: 45px;
    }

    .price h6 {
        margin: 0;
    }

    .hemburgur {
        margin-left: 5px;
    }

    .featurerow {
        margin-bottom: 15px;
    }

    .advantagesbox,
    .bundlebox,
    .plans.smarthome .count .App,
    .planbanner,
    .partnerbox {
        margin: 35px 0;
    }

    .price ul li:before {
        height: 1px;
    }

    .enquireform2 {
        padding: 80px 0;
    }

    .featurecont {
        padding: 50px 20px;
    }

    .technologystn .title h3 {
        line-height: 55px;
    }

    .dropdown .logo {
        left: 4.5%;
    }

    .menubox {
        width: 85%;
    }

    .fixed .dropdown .logo {
        left: 0.25%;
    }

    .solutionimg,
    .solutioncont {
        width: 48%;
    }

    .advantagesbox {
        width: 17%;
    }

    .banner.booknow.filter.m-0.h-120:before {
        height: 100px;
        width: 150px;
    }

    .circleicons ul {
        gap: 25px;
    }

    .listpoint h5 {
        font-size: 21px;
        line-height: 40px;
    }

    .listpoint h5 {
        font-size: 21px;
        line-height: 40px;
    }

    .sliderrow.slide05 .circle01.align-self-end {
        padding: 0 40px;
        align-items: center;
    }

    .sliderrow.slide05 .circle01.align-self-end ul li {
        line-height: initial;
    }

    .bundlebox ul li strong {
        font-size: 26px;
        line-height: 32px;
    }

    .navbar ul li {
        font-size: 13px;
    }
}

@media all and (max-width:1420px) {
    .slide05 .slideimg {
        width: 50%;
    }

    .business .technobox {
        width: 20%;
    }

    .appcta span a {
        padding: 0 3px;
    }

    .dropdown .logo {
        left: 4%;
        top: 12%;
    }

    .fixed .dropdown .logo {
        left: 0.25%;
        top: 17%;
    }
}

@media all and (max-width:1380px) {
    .gap_4 {
        gap: 15px;
    }

    .slide05 .slideimg {
        width: 44%;
    }

    .slide05 .listpoint ul li {
        margin: 9px 0;
    }

    .accordion .accordion-item.active .panel {
        padding: 15px;
    }

    .accordion .accordion-item .title {
        padding: 10px 20px;
    }

    .listpoint ul li strong,
    .heading h4,
    .btechnoimg h4,
    .technocont h4,
    .circleicons ul li h5 {
        font-size: 20px;
    }

    .advantagestn h4 {
        line-height: 26px;
    }

    .ftrbox ul li,
    .pricestn li,
    .ptag h5,
    .servicecont h4 {
        font-size: 19px;
        line-height: 22px;
    }

    .pricestn h3,
    .ftrbox ul li,
    .androidtv .bundlebox h4,
    .androidbox ul li p:last-child {
        font-size: 14px;
    }

    .pricestn h3 strong,
    .pricestn li strong,
    .image_plans_div P,
    .accordion .accordion-item .title:after,
    .advantagestn h4,
    .bundlebox ul li strong {
        font-size: 24px;
    }

    .booknow a {
        font-size: 10px;
        letter-spacing: 1.5px;
        line-height: 30px;
        padding: 0 15px;
    }

    .circlimg h2,
    .appbox h3,
    .title h4,
    .squarecont li span,
    .pack h5 strong {
        font-size: 26px;
        line-height: 28px;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop {
        line-height: 30px;
        letter-spacing: 1.5px;
    }

    .title h2,
    .title h3,
    .bannercont h2,
    .countnum,
    .countnum h3,
    .plancont h4 {
        font-size: 36px;
        line-height: 42px;
    }

    .price h6,
    .bundlebox h4 {
        font-size: 32px;
        line-height: 40px;
    }

    body,
    .listpoint ul li,
    .containers label,
    .hungamacont ul li,
    .countnum p,
    .highlights ul li span,
    .pack h5,
    .bundlebox ul li {
        font-size: 16px;
        line-height: 24px;
    }

    .react-tabs__tab-list li,
    .labelcont p {
        font-size: 12px;
    }

    .advantagecont,
    .highlights ul li,
    .androidbox ul li p {
        font-size: 18px;
        line-height: 22px;
    }

    .react-tabs__tab-list li,
    .androidtv .bundlebox h4 {
        line-height: 45px;
    }

    .buttnprop span {
        letter-spacing: 2px;
    }

    .copyrights p,
    .buttnprop span {
        font-size: 11px;
    }

    .squarecont li strong {
        font-size: 50px;
        line-height: 50px;
    }

    .dropdown .logo {
        left: 5.5%;
        top: 16%;
    }

    .fixed .dropdown .logo {
        top: 22%;
    }

    .menubox {
        padding-top: 20vh;
    }

    .header {
        padding: 15px 0;
    }

    .pricestn h3,
    .pricestn .booknow,
    .mngcircle .booknow {
        margin-top: 10px;
    }

    .title,
    .react-tabs__tab-list,
    .booknow.filter .footerform .form ul li,
    .heading {
        margin-bottom: 35px;
    }

    .butn,
    .servicecont {
        margin-top: 35px;
    }

    .hemburgermenus .dropdown {
        padding-bottom: 60px;
    }

    .title span,
    .bundlebox h4,
    .react-tabs__tab-list li {
        padding: 0 25px;
        letter-spacing: 2px;
        line-height: 40px;
    }

    .sprice {
        margin-bottom: 15px;
    }

    .btechnocont li:before {
        width: 20px;
    }

    .circleicons ul li,
    .btechnoimg h4,
    .circleicons ul li h5 {
        margin: 0;
    }

    .bundlebox {
        padding: 35px;
    }

    .banner:before {
        width: 140px;
        height: 140px;
    }

    .advantageicon span,
    .circleicons ul li span {
        width: 140px;
        height: 140px;
    }

    .advantageicon span img,
    .circleicons ul li span img {
        width: 40%;
    }

    .hemburgur {
        width: 30px;
        margin-left: 10px;
    }

    .sliderrow {
        width: 95%;
        margin: 0px auto;
        margin-top: 80px;
    }

    header {
        height: 80px;
    }

    .circle02 {
        width: 200px;
        height: 200px;
        top: -20%;
    }

    .circle01,
    .hungamacircle {
        width: 365px;
        height: 365px;
    }

    .footerform .form ul li:last-child,
    .buttnprop {
        width: 150px;
        height: 30px;
    }

    .ptag {
        width: 120px;
        height: 120px;
    }

    .ottimg img {
        width: 75%;
    }

    .hemburgermenus {
        width: 220px;
    }

    .slideimg img {
        width: 90%;
    }

    .bundlebox ul li:before,
    .coreadvantages .squarecont ul li ul li::before {
        width: 40px;
        height: 0.5px;
    }

    #jujharnumber .buttnprop {
        width: auto;
    }

    #jujharnumber .buttnprop span {
        padding: 0 15px;
    }

    .footer {
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    .ftrbox ul li:first-child {
        letter-spacing: 1px;
        margin-bottom: 10px;
    }

    .androidbox ul li p:last-child {
        line-height: 26px;
        font-size: 19px;
    }

    .form h2,
    .price h6 {
        font-size: 30px;
        line-height: 36px;
    }

    .freepacks li strong {
        font-size: 50px;
        line-height: 30px;
    }

    .pricestn {
        position: relative;
        z-index: 9;
    }

    .circle02 h2 {
        font-size: 26px;
        line-height: 26px;
    }

    .hemburgermenus .dropdown .sitemapcol ul li {
        margin: 3px 0;
        padding: 3px 0;
    }

    .ftrbox ul li:first-child {
        font-size: 15px;
        line-height: 22px;
    }

    .footerform .form ul li input {
        line-height: 35px;
    }

    .filter .form li select {
        height: 35px;
    }

    .contactbox h3 {
        font-size: 20px;
        letter-spacing: 1.5px;
        border-width: 3px;
        padding-left: 5px;
    }

    .contactbox li {
        margin: 5px 0;
        padding: 5px 0;
    }

    .officebox h4 {
        font-size: 22px;
        line-height: 26px;
    }

    .office {
        width: 300px;
        height: 250px;
    }

    .contactbox li span {
        font-size: 12px;
        letter-spacing: 2px;
    }

    .slick-dots li button {
        padding: 0;
        width: auto;
        height: auto;
    }

    .slick-dots li {
        width: 12px;
    }

    .slick-dots li button:before {
        font-size: 7px;
    }

    .navbar ul li {
        letter-spacing: 1.2px;
        font-size: 12px;
        padding: 0 6px;
    }

    .circle01 {
        padding: 50px;
    }
}

@media all and (max-width:1360px) {

    .featurebox h4,
    .featureInfo {
        font-size: 19px;
        line-height: 22px;
    }

    .dropdown .logo {
        left: 6.5%;
    }

    .ftrbox ul li {
        letter-spacing: 0.5px;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
    .navbar ul li {
        font-size: 10px;
    }

    .bundlebox ul li {
        padding: 5px 0;
    }
}

@media all and (max-width:1280px) {
    .navbar ul li {
        letter-spacing: 1px;
    }

    .hemburgermenus {
        width: 180px;
    }

    .sliderrow {
        width: 100%;
    }

    .title h2,
    .title h3 {
        font-size: 32px;
        line-height: 40px;
    }

    .react-tabs__tab-list li {
        line-height: 35px;
        font-size: 11px;
        padding: 0 20px;
        letter-spacing: 2px;
    }

    .count {
        flex-wrap: wrap;
        margin-top: 25px;
    }

    .plans.smarthome .App::before {
        display: block;
    }

    .plans.smarthome .App:nth-child(1):before {
        display: none;
    }

    .smartcont {
        width: 80%;
    }

    .technologystn .title h3 {
        line-height: 44px;
    }

    .banner:before {
        width: 135px;
        height: 135px;
    }

    .jujhar {
        width: 70px;
    }

    .jujhar svg {
        width: 100%;
    }

    .dropdown .logo {
        left: 40px;
        top: 26px;
    }

    .fixed .dropdown .logo {
        top: 35px;
    }

    .title span {
        font-size: 10px;
        letter-spacing: 2px;
        line-height: 40px;
    }

    .jabtout {
        width: 100%;
        margin: 0;
    }

    body,
    .containers label {
        font-size: 14px;
        line-height: 22px;
    }

    .servicecont {
        margin-top: 15px;
    }

    .servicecont h4,
    .androidbox ul li p {
        font-size: 17px;
        line-height: 24px;
    }

    .slick-slider .slick-next,
    .slick-slider .slick-prev {
        width: 32px;
        height: 32px;
    }

    .appbox h3 {
        font-size: 22px;
    }

    .appcta span,
    .labelcont p {
        font-size: 11px;
        line-height: 30px;
        padding: 0 10px;
    }

    .appleft {
        width: 40%;
    }

    .countnum,
    .countnum h3 {
        font-size: 40px;
        line-height: 40px;
    }

    .btechnoimg h4,
    .technocont h4,
    .circleicons ul li h5 {
        font-size: 20px;
    }

    .banner.booknow.filter.m-0.h-120:before {
        height: 80px;
        width: 125px;
    }

    .advantagesbox {
        width: 25%;
    }

    .hdbundle::before,
    .squareshape::before,
    .enterprise::before,
    .partner::before,
    .androidtv::before,
    .androidtvrow::after,
    .androidbox::after {
        width: 220px;
        height: 220px;
    }

    .web-container {
        width: 85%;
    }

    .bundlebox h4 {
        font-size: 26px;
    }

    .gradienticons ul li span {
        width: 100px;
        height: 100px;
    }

    .advantagecont {
        height: 150px;
    }

    .banner,
    .number {
        height: 85vh;
    }

    .banner.booknow.filter.m-0.h-120 {
        height: 100px;
    }

    .ftrbox ul li:first-child {
        font-size: 14px;
        line-height: 20px;
    }
}

@media all and (max-width:1152px) {
    .slide05 .listpoint ul li {
        font-size: 14px;
        line-height: 15px;
    }

    .navbar ul li:nth-child(7),
    .navbar ul li:nth-child(6) {
        display: none;
    }

    .banner:before {
        width: 115px;
        height: 115px;
    }

    .navbar ul li a svg {
        width: 12px;
    }

    .logo {
        width: 100px;
        margin-right: 5%;
    }

    .navbar ul:last-child {
        gap: 15px;
    }

    .navbar ul li:nth-child(7),
    .navbar ul li:nth-child(6) {
        padding: 0 10px;
    }

    .circle01,
    .hungamacircle {
        width: 320px;
        height: 320px;
    }

    .circle02 h2 {
        font-size: 28px;
        line-height: 30px;
    }

    .circle02 {
        width: 200px;
        height: 200px;
    }

    .circle01 {
        padding: 50px;
        padding-right: 50px;
    }

    .pricestn li {
        font-size: 16px;
        line-height: 18px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 36px;
    }

    .circlimg img {
        width: 75%;
    }

    .pricestn h3 {
        font-size: 13px;
    }

    .menubox {
        gap: 25px;
    }

    .sitemapcol h4 {
        font-size: 11px;
    }

    .pricestn h3 strong {
        line-height: 20px;
    }

    .dropdown .logo {
        left: 120px;
        top: 20px;
    }

    .pricestn li:first-child {
        margin: 0;
    }

    section,
    .chooseplan,
    .plans,
    .faq .container,
    .androidtvrow,
    .number.aboutnetplus .container {
        padding: 30px 0;
    }

    .web-container {
        width: 90%;
    }

    .appleft {
        padding: 40px 20px;
    }

    .appbox h3 {
        margin: 0;
    }

    .featurebox h4,
    .featureInfo {
        font-size: 17px;
        line-height: 20px;
    }

    .ptag {
        width: 100px;
        height: 100px;
    }

    .featurebox span {
        font-size: 100px;
    }

    .number .title {
        margin-top: 80px;
        margin-bottom: 0;
    }

    .ftrbox ul li {
        font-size: 12px;
        letter-spacing: 0.3px;
        line-height: 18px;
    }

    .ftrbox ul li:first-child {
        font-size: 11px;
    }

    footer {
        padding-top: 50px;
    }

    .copyrights p {
        font-size: 9px;
        line-height: 30px;
    }

    .filter .form li select,
    .footerform .form ul li input {
        line-height: 30px;
        height: 30px;
    }

    .footerform .form ul li input::placeholder,
    .filter .form li select {
        letter-spacing: 1.2px;
    }

    .booknow.filter .footerform .form ul li textarea,
    .footerform .form ul li input::placeholder,
    .footerform .form ul li select,
    .footerform .form ul li input {
        font-size: 10px;
    }

    .btechnocont li {
        text-align: center;
    }

    .form h2 {
        font-size: 26px;
        line-height: 30px;
    }

    .technobox {
        width: 30%;
    }
}

@media all and (max-width:1020px) {
    .slide05 .slideimg {
        width: 60%;
    }

    .gap_4 {
        flex-wrap: wrap;
    }
}

@media all and (max-width:800px) {

    .desktop-show,
    .slidecont.desktop-show,
    .navbar .desktop-show,
    .hdbundle::before,
    .partner::before,
    .enterprise::before,
    .squareshape::before,
    .broadband::before,
    .service:before,
    .retail::before,
    .androidtv::before,
    #banner:after,
    .hdbundle::before,
    .androidbox::after,
    .androidtv::before,
    .aboutus:after,
    .enterprise::before,
    .broadband::before,
    .service:before,
    .service .title::after,
    .app:after,
    .feature:after,
    .feature:before,
    .technologystn:after,
    .aboutus:before,
    .retail::before,
    .broadband::after,
    .squareshape::before,
    #banner:after,
    .partner::before,
    .androidtvrow::after,
    .hdbundle::after,
    .hemburgermenus,
    .navbar ul li a.desktop-show,
    .App:nth-child(odd):before,
    .sliderrow.slide05 img.mobile-show {
        display: none;
    }

    .mobile-show,
    .sliderrow.slide05 img.mobile-show,
    .navbar ul li a.mobile-show {
        display: block;
    }

    .App {
        width: 50%;
    }

    .navbar ul li:hover .dropdown,
    .menu.active .hemburgermenus .dropdown {
        height: 100vh;
    }

    .dropdown {
        align-items: center;
        background: #4d4d4d;
        z-index: 99;
    }

    .menu.active .hemburgermenus .submenu,
    .navbar ul li:hover .dropdown .submenu {
        background-image: none;
        display: flex;
        align-items: center;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li,
    .hemburgermenus .dropdown .sitemapcol ul li,
    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child,
    .menu.active .navbar ul li,
    .sitemapcol h4 {
        padding: 5px 0;
        margin: 5px 0;
        font-size: 19px;
        line-height: 30px;
        text-transform: capitalize;
        letter-spacing: 1px;
    }

    .hemburgermenus .sitemapcol ul:last-child {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
    }

    .hemburgermenus .sitemapcol ul:last-child li {
        border: none;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 16px;
    }

    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child,
    .sitemapcol h4 {
        border-bottom: solid 1px rgb(241 241 241 / 25%);
        font-weight: normal;
        font-family: "Franklin Gothic Book";
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li:last-child,
    .sitemapcol:last-child h4 {
        border: none;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li {
        width: 100%;
        border-bottom: 1px solid #282828;
    }

    .menu.active .navbar ul li {
        border-bottom: 1px solid hsla(0, 0%, 95%, .25);
        color: #fff;
        line-height: 40px;
    }

    .menu.active .navbar ul li:last-child {
        border: none;
    }

    .menu.active .navbar ul li a {
        color: #fff;
    }

    .menubox {
        display: inline-block
    }

    .hemburgermenus .sitemapcol .mmenubox ul,
    .gradienticons ul {
        flex-wrap: wrap;
        margin: 0;
    }

    button.hemburgur.active span {
        background: #fff !important;
    }

    .hemburgermenus .sitemapcol ul li:nth-child(1) span:before {
        content: '\2039';
        font-size: 32px;
        position: absolute;
        left: 0;
        transform: translateX(-50%);
        top: 35%;
        line-height: 0;
        font-weight: 100;
        font-family: system-ui;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li span {
        padding-left: 8px;
        color: #fff;
        opacity: 0.5;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 14px;
        position: relative;
    }

    .navbar ul:last-child {
        gap: 5px;
    }

    .dropdown .logo {
        left: 20px;
    }

    .navbar ul li {
        letter-spacing: 0.5px;
    }

    .fixed .dropdown .logo {
        top: 32px;
        left: -3px;
    }

    .logo {
        width: 80px;
    }

    .banner:before {
        display: none;
    }

    .jujhar {
        width: 60px;
        height: 30px;
    }

    .circle02 {
        width: 150px;
        height: 150px;
    }

    .circle02 h2,
    .advantagestn h4 {
        font-size: 22px;
        line-height: 24px;
    }

    .circlimg img {
        width: 70%;
        float: left;
        margin-left: 20%;
    }

    .pricestn li {
        font-size: 14px;
        line-height: 14px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 26px;
        left: 50%;
    }

    .listpoint ul li strong,
    .pricestn li strong,
    .pricestn h3 strong {
        font-size: 17px;
        line-height: 22px;
    }

    .pricestn {
        margin-top: 10px;
        text-align: center;
        position: relative;
        z-index: 9;
    }

    .pricestn h3 {
        font-size: 11px;
    }

    .circlimg h2 {
        font-size: 22px;
        line-height: 26px;
    }

    .circle01 {
        padding: 42px;
    }

    .fixed .headerstn {
        padding: 5px 0;
    }

    .booknow a {
        margin-top: 8px;
        font-size: 11px;
        padding: 0 20px;
        margin-top: 0;
    }

    .slick-dots li button:before {
        font-size: 5px;
    }

    .number .title {
        margin-bottom: 35px
    }

    .slick-dots li {
        width: 10px;
        height: 10px;
    }

    .booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop {
        font-size: 9.5px;
    }

    .logo {
        width: 20%;
        margin: 0;
        position: relative;
        z-index: 2;
    }

    .fixed .logo {
        width: 15%;
    }

    .headerstn,
    .hemburgermenus .dropdown {
        padding: 0;
    }

    .hemburgur {
        width: 50px;
        margin: 0;
        height: 50px;
    }

    .hemburgur span,
    .hemburgur span:nth-child(3) {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #fff;
    }

    .slideimg img,
    .hemburgermenus .dropdown,
    .andoidcont,
    .jabcont,
    .aboutimg,
    .booknow .heading {
        width: 100%;
    }

    .slideimg,
    .slidecont {
        width: 50%;
    }

    .sliderrow {
        margin-top: 100px;
    }

    .slick-dots {
        bottom: 0;
    }

    .menu.active .hemburgermenus .submenu {
        display: flex;
        align-items: center;
    }

    .menu.active .hemburgermenus .dropdown {
        height: 100vh;
        align-items: inherit;
    }

    .hemburgermenus .sitemapcol {
        width: 90%;
        margin: 0px 5%;
    }

    .menubox {
        gap: 0;
        padding: 0;
        width: 100%;
    }

    header.whitelogo .logo path {
        fill: #fff !important;
    }

    .hemburgur.active span:nth-child(3),
    .hemburgur.active span:nth-child(1) {
        top: 25px;
    }

    .fixed .headerstn .menu {
        width: auto;
    }

    .menu.active .hemburgermenus .dropdown .dropdown.slideeffect {
        opacity: 1;
        width: 100%;
    }

    .menu.active .hemburgermenus .dropdown .dropdown {
        padding: 0;
        opacity: 0;
        height: 100vh;
        transform: initial;
        width: 0;
        position: fixed;
        background: #1c1c1e !important;
        display: flex;
    }

    .plans {
        border-radius: 0 0 0 200px;
    }

    .ftrbox ul li:first-child {
        margin-bottom: 5px;
        font-size: 15px;
    }

    .enquireform2 {
        border-radius: 70px 70px 0px 0;
        padding: 50px 0;
    }

    .bannercont {
        width: 60%;
    }

    .title p {
        width: 80%;
    }

    .circleicons ul {
        flex-wrap: wrap;
    }

    .circleicons ul li {
        width: 30%;
    }

    .circleicons ul li span {
        margin-bottom: 15px;
    }

    .squarecont ul {
        flex-wrap: wrap;
    }

    .squarecont li {
        width: 30%;
    }

    .countnum,
    .countnum h3,
    .solutioncont h4,
    .plancont h4 {
        font-size: 32px;
    }

    .advantagecont {
        padding: 20px;
    }

    .netplusadvantages h4 {
        font-size: 16px;
    }

    .androidfeature ul {
        gap: 20px;
    }

    .planbanner {
        border-radius: 100px 100px 0px 0px;
        height: 50vh;
    }

    .bundlebox h4 {
        padding: 10px 20px;
        font-size: 29px;
        line-height: 36px;
    }

    .androidbox .squarecont li {
        width: 80%;
    }

    .bundlebox {
        width: 45%;
    }

    .packs.coreadvantages .squarecont li {
        width: 46%;
    }

    .coreadvantages .squarecont li span {
        font-size: 24px;
        line-height: 30px;
    }

    .packstn .squarecont ul li strong {
        font-size: 28px;
        line-height: 30px;
    }

    .packs.coreadvantages .squarecont li ul li,
    .slick-track .slick-slide:nth-child(even) .sliderrow {
        width: 100%;
    }

    .packs .planbanner {
        background-attachment: inherit;
        height: auto;
        padding: 50px 0;
    }

    .hungamacircle {
        padding: 50px;
    }

    .hungamacircle {
        margin: 0px auto;
    }

    .hungamabox {
        flex-wrap: wrap;
    }

    .hungamabox {
        margin-bottom: 50px;
        display: grid;
    }

    .hungamafrow {
        width: 100%;
        margin: 0;
    }

    .highlights {
        margin-top: 25px;
        padding-top: 25px;
    }

    .highlights ul li {
        width: 50%;
    }

    .banner.jujhargroup {
        background-image: url(../images/mjujhar-brands.webp) !important;
        height: 100vh;
        margin-top: 10vh;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .jabcont {
        text-align: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }

    .jabcont .title {
        text-align: center;
    }

    .jabtout .butn {
        margin: 0px auto;
        display: flex;
        justify-content: center;
    }

    .aboutimg {
        margin: 20px 0;
    }

    .banner.jujhargroup {
        margin-top: 5vh;
    }

    .medright,
    .medleft {
        width: 100%;
        margin-bottom: 25px;
    }

    .mediastn {
        flex-wrap: wrap;
    }

    .medleft ul {
        display: flex;
        padding: 15px;
        justify-content: center;
    }

    .medleft ul li {
        width: auto;
        margin: 0 20px;
        padding: 0 50px;
        border: none;
        border-right: 1px solid #a1a1a2;
        margin-right: 0;
    }

    .years ul {
        justify-content: center !important;
    }

    .hemburgur::before {
        content: "";
        background-image: url(../images/hemburgur-icon.webp);
        position: absolute;
        z-index: 1;
        left: 150%;
        height: 220px;
        background-repeat: no-repeat;
        width: 200px;
        top: 0;
        background-size: contain;
        background-position: center;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    .fixed .hemburgur::before {
        opacity: 0;
    }

    .pricestn li:first-child {
        margin-bottom: 8px;
    }

    .freepacks li strong {
        font-size: 40px;
        line-height: 10px;
    }

    .ftrbox {
        margin-bottom: 15px;
    }

    .ftrbox,
    .ftrbox.address,
    .footer:nth-child(2) .ftrbox {
        width: 40%;
    }

    .footer {
        flex-wrap: wrap;
    }

    .ftrbox:nth-child(1) {
        width: 100%;
        float: left;
    }

    .ftrbox:nth-child(1) svg {
        width: 30%;
    }

    .ftrbox {
        margin-bottom: 15px;
    }

    .ftrbox ul li {
        font-size: 14px;
        line-height: 20px;
    }

    .logo.active path {
        fill: #fff !important;
    }

    .menu .navbar {
        height: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: all .5s ease;
        width: 0;
        z-index: 1;
        background-image: linear-gradient(120deg, #6a3de2, #af2c7e 50%, #ec3047);
    }

    .menu.active .navbar {
        height: 100vh;
        opacity: 1;
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        visibility: visible;
        width: 100%;
    }

    header {
        height: auto;
    }

    .navbar ul {
        display: inline-block;
        margin: 0 5%;
        width: 90%;
    }

    .contactbox {
        width: 40%;
    }

    .navbar ul li a.active:before {
        bottom: -5px;
    }

    .banner.mngslide .sliderrow .slideimg img {
        left: -10%;
    }

    .navbar ul li:nth-child(6) {
        display: block;
        text-align: left;
        border-radius: 0;
        background-color: transparent;
        overflow: visible;
    }

    .navbar ul li:nth-child(6)::before {
        content: none;
    }

    .navbar ul li:nth-child(6) a.active::after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -5px;
        left: 0;
        background: #d51e3f;
        transition: ease all 0.5s;
    }
}

@media all and (max-width:680px) {
    .bundlebox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .packmodule {
        gap: 10px;
    }

    .freepacks:nth-child(1) {
        padding: 5px;
    }

    .packmodule {
        margin: 25px 0 0 0;
    }

    .technocont ul li::before,
    .btechnocont li:before {
        left: 50%;
        transform: translate(-50%);
    }

    .contactbox {
        width: 100%;
    }

    .business .technobox,
    .technobox {
        width: 45%;
    }

    .circleicons ul li span svg {
        width: 60px;
    }

    .contactoverview {
        flex-wrap: wrap;
    }

    .office {
        width: 100%;
        height: auto;
    }

    .plans.smarthome .count .App {
        width: 50%;
    }

    .price h6 {
        margin-bottom: 25px;
    }

    .plans.smarthome .count .App:nth-child(odd):before {
        display: none;
    }

    .androidfeature {
        margin-top: 50px;
    }

    .circle01,
    .hungamacircle {
        width: 220px;
        height: 220px;
    }

    .squarecont li strong {
        font-size: 40px;
        line-height: 40px;
    }

    .squarecont li span {
        font-size: 22px;
        line-height: 22px;
    }

    .slideimg {
        width: 60%;
    }

    .slidecont {
        width: 40%;
    }

    .pricestn li {
        font-size: 11px;
        line-height: 12px;
    }

    .pricestn li strong {
        font-size: 15px;
        line-height: 20px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 19px;
    }

    .pricestn h3 {
        font-size: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .pricestn h3 strong {
        margin-left: 5px;
    }

    .circle01 {
        padding: 25px;
    }

    .accordiancont p {
        margin-bottom: 5px;
    }

    .bundlebox {
        width: 47%;
        padding: 15px;
    }

    .highlights ul {
        flex-wrap: wrap;
    }

    .highlights ul li {
        margin: 15px 0;
    }

    .bundlebox .buttnprop {
        position: static;
        transform: inherit;
    }

    .circle01.align-self-end .mngcircle .d-flex {
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    .circle01.align-self-end .mngcircle .d-flex .listpoint {
        width: 50%;
    }

    .circle01.align-self-end .mngcircle .d-flex .listpoint ul {
        display: none;
    }

    .sliderrow.slide05 .circle01.align-self-end .mngcircle {
        width: 100%;
    }
}

@media all and (max-width:630px) {
    .gap_4 {
        justify-content: center;
    }

    .hide_980 {
        display: none !important;
    }

    .show_980 {
        display: block !important;
        position: relative;
        margin: 0 auto;
    }

    .sliderrow.slide05 {
        flex-direction: column;
        display: flex;
        width: 105%;
        float: left;
    }

    .slide05 .slideimg {
        margin-top: 0;
        width: 100%;
    }

    .slide05 .slidecont {
        width: auto;
        position: relative;
        right: 0;
    }

    .slide05 .circle02 {
        left: 70%;
        width: 180px;
        height: 180px;
    }



    .slideimg img {
        left: -30%;
    }

    .banner.mngslide .slidecont {
        justify-content: start;
        left: 0;
    }

    .popimg {
        width: 90%;
        top: -15%;
    }

    .planstab.antivirus .slick-slider .slick-next {
        right: -25px;
    }

    .planstab.antivirus .slick-slider .slick-prev {
        left: -25px;
    }

    .partnerbox ul li {
        width: 25%;
    }

    .lg-react-element a {
        width: 46%;
    }

    .banner.mngslide .slideimg {
        position: relative;
        right: -25%;
        margin-top: 25vh;
    }

    .hemburgermenus .sitemapcol ul,
    .dropdown li,
    .dropdown li a,
    .banner.mngslide .sliderrow {
        width: 100%;
        float: left;
    }

    .sliderrow {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        position: relative;
        margin-top: 18vh;
    }

    .slideimg {
        width: 140%;
        justify-content: flex-start;
        margin-top: 15vh;
    }

    .slidecont {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        z-index: auto;
    }

    .circle02 {
        left: inherit;
        right: 30%;
    }

    .circle01,
    .hungamacircle {
        width: 320px;
        height: 320px;
    }

    .banner:before {
        width: 160px;
        height: 210px;
        background-size: contain;
        right: -13%;
    }

    .gradientapp {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .appleft,
    .apprgt,
    .appleft.form,
    .smartcont,
    .booknow.filter .footerform {
        width: 100%;
        position: static;
        border-radius: 0;
        margin: 0;
    }

    .advantagesbox {
        width: 46%;
        margin-bottom: 0;
    }

    .appleft {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .feartureimg,
    .featurecont,
    .aboutimg,
    .slick-track .slick-slide:nth-child(even) .sliderrow,
    .technology ul,
    .title h3 {
        width: 100%;
    }

    .featurerow {
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    .featurecont {
        margin-top: 25px;
    }

    .plancont h4,
    .plancont h4 {
        font-size: 30px;
        line-height: 40px;
    }

    .price h6 {
        font-size: 40px;
    }

    .ptag {
        width: 120px;
        height: 120px;
    }

    .ptag h5 {
        font-size: 24px;
        line-height: 30px;
    }

    .jabtout {
        flex-wrap: wrap;
    }

    .jabcont {
        width: 100%;
        margin-top: 50px;
    }

    .form h2 {
        margin-bottom: 25px;
    }

    .footerform .form ul li {
        width: 100%;
        margin-bottom: 15px;
    }

    .footerform .form ul li:last-child {
        margin-top: 35px;
    }

    .form {
        width: 70%;
        margin: 0px 15%;
    }

    .footer {
        flex-wrap: wrap;
    }

    footer .footer:nth-child(1) .ftrbox:first-child {
        width: 100%;
        margin-bottom: 36px;
    }

    .ftrbox:first-child svg {
        width: 40%;
    }

    .footer {
        padding-left: 0;
        padding-right: 0;
        border: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .ftrbox.address,
    .ftrbox {
        width: 40%;
    }

    .ftrbox ul li:first-child {
        font-size: 16px;
        letter-spacing: 1px;
    }

    .ftrbox ul {
        margin-bottom: 20px;
    }

    .ftrbox ul li {
        font-size: 13px;
        letter-spacing: 0.5px;
        line-height: 20px;
    }

    .copyrights {
        border-top: solid 1px #ededed;
        margin-top: 10px;
        flex-wrap: wrap;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .enquireform2 .row {
        margin: 0;
        padding: 0;
    }

    .hemburgermenus .sitemapcol ul:last-child li {
        font-size: 13px;
    }

    .slick-track .slick-slide:nth-child(even) .sliderrow .slidecont {
        justify-content: start;
        left: 0;
    }

    .slick-track .slick-slide:nth-child(even) .sliderrow .slideimg img {
        right: -20%;
        left: inherit;
        top: 14vh;
    }

    .banner.h-auto .slick-list {
        overflow: visible;
    }

    #banner.aos-init.aos-animate:before {
        height: 50%;
    }

    .slick-dots {
        bottom: 3.5%;
    }

    .slick-dots li button:before {
        font-size: 7px;
    }

    .containers label {
        margin: 0 15px;
        font-size: 17px;
        line-height: 24px;
    }

    .mngcircle {
        position: relative;
        z-index: 9999;
    }

    .listpoint ul li strong,
    .pricestn h3 strong,
    .androidbox ul li p {
        font-size: 19px;
    }

    .chooseplan {
        border-radius: 50px;
    }

    .pricestn li {
        font-size: 20px;
        line-height: 26px;
    }

    .pricestn li strong {
        font-size: 26px;
        line-height: 32px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 40px;
        left: 50%;
    }

    .copyrights p {
        justify-content: center;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        line-height: 20px;
        font-size: 12px;
    }

    .advantagecont {
        padding: 20px;
        text-align: center;
        align-items: center;
    }

    .androidfeature ul,
    .bundlestn {
        gap: 0px;
    }

    .androidfeature ul li,
    .bundlestn ul li {
        margin: 15px 0;
    }

    .planbanner {
        border-radius: 100px 100px 0px 0px;
        height: 50vh;
    }

    .androidtvrow,
    .androidfeature {
        margin-top: 25px;
    }

    .plancont h4 {
        font-size: 24px;
        line-height: 30px;
    }

    .squarecont li {
        width: 48%;
    }

    .androidtvrow {
        flex-wrap: wrap;
        padding: 50px;
        text-align: center;
    }

    .androidbox7,
    .androidtvrow3 {
        width: 100%;
    }

    .androidtvrow:before {
        border-radius: 100px;
        right: 0;
    }

    .androidbox7 .buttnprop {
        justify-content: center;
        margin: 0px auto;
        margin-top: 25px;
    }

    .bundlebox ul li {
        padding: 10px 0;
        font-size: 19px;
    }

    .price ul li {
        padding: 10px 0;
    }

    .ottslider {
        width: 90%;
        margin: 0px auto;
    }

    .image_plans_div P,
    .servicecont h4 {
        font-size: 21px;
    }

    body {
        font-size: 17px;
        line-height: 24px;
    }

    .slide05 .slideimg img {
        left: 0% !important;
        right: inherit !important;
        top: initial !important;
    }

    .m_left_align {
        left: -20% !important;
        top: 5vh;
    }
}

@media all and (max-width:480px) {
    .slide05 .listpoint ul {
        height: 50px;
        overflow: auto;
    }

    .gap_4 {
        gap: 20px;
    }

    .slide05 .mngcircle .booknow {
        margin-top: 20px;
    }

    .slide05 .circle01 {
        height: 250px;
        width: 250px;
    }

    .slide05 .circle02 {
        left: 60%;
        top: -30%;
        width: 150px;
        height: 150px;
    }

    .sliderrow.slide05 {
        width: auto;
        gap: 15px;
        margin-top: 12vh;
        margin-bottom: 40px;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li,
    .hemburgermenus .dropdown .sitemapcol ul li,
    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child,
    .menu.active .navbar ul li,
    .sitemapcol h4 {
        padding: 3px 0;
        margin: 3px 0;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.5px;
    }

    section#faq.mobile-minus {
        margin-top: -50px;
    }

    .circle02 {
        top: -5%;
        right: 0;
    }

    .technology ul li {
        width: 28%;
    }

    .freepacks li span {
        padding: 2px 10px;
        letter-spacing: 1px;
        font-size: 8px;
    }

    .freepacks li strong {
        font-size: 32px;
        line-height: 32px;
        padding: 0;
    }

    .freepacks li strong small {
        font-size: 9px;
        letter-spacing: 1px;
        margin-left: 5px;
    }

    .appcta span {
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 13px;
        line-height: 40px;
    }

    .number {
        height: 100vh;
        background-position: top;
    }

    .featurebox h4,
    .featureInfo {
        font-size: 22px;
        line-height: 24px;
    }

    .title {
        width: 90%;
        margin: 0 5%;
        margin-bottom: 25px;
    }

    .planslider {
        width: 90%;
        margin: 0 5% !important;
        margin-bottom: 25px;
    }

    .bannercont h2 {
        font-size: 28px;
        line-height: 32px;
    }

    .banner.jujhargroup {
        margin-top: 5vh;
    }

    .circleicons ul li {
        width: 45%;
        align-items: flex-start;
    }

    .banner {
        height: 50vh;
    }

    .antiviruspacks {
        margin-top: 40px;
    }

    .title p,
    .smartcont,
    .ottimg img {
        width: 100%;
    }

    .title h4,
    .pack h5 strong {
        font-size: 22px;
        line-height: 26px;
    }

    .advantagestn h4 {
        line-height: 26px;
    }

    .circleicons ul {
        gap: 25px;
    }

    .banner:before {
        width: 130px;
        height: 180px;
        background-size: contain;
        right: -16%;
    }

    .logo {
        width: 30%;
    }

    .fixed .logo {
        width: 25%;
    }

    .plans {
        border-radius: 0 0 0 100px;
    }

    .hemburgermenus .sitemapcol ul:last-child {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
    }

    .hemburgermenus .sitemapcol ul:last-child li,
    .footerform.bookingform .form,
    .planstab.antivirus {
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .servicebox {
        text-align: center;
        width: 100%;
    }


    .responsive-img {
        width: 100%;
        height: auto;
      }
      
      
      @media (max-width: 600px) {
        .responsive-img {
          width: 80%; 
          margin: 0 auto; 
        }
      }
      

    .servicecont .butn {
        justify-content: center;
    }

    .servicecont p {
        height: inherit;
    }

    .technology ul li {
        width: 28%;
    }

    .footer:nth-child(2) .ftrbox,
    .ftrbox.address,
    .ftrbox {
        width: 50%;
    }

    .copyrights p a {
        padding: 0 5px;
    }

    .circlimg img {
        margin-bottom: 10px;
    }

    .react-tabs__tab-list li {
        line-height: 16px;
        padding: 10px 15px;
    }

    .featurestn {
        width: 100%;
    }

    .banner:before {
        width: 130px;
        height: 190px;
        right: -19%;
    }

    .planstab,
    .jabcont,
    .jabtout.advantagestn .aboutimg {
        margin: 0;
    }

    .ftrbox ul li:first-child {
        font-size: 15px;
    }

    .form {
        width: 80%;
        margin: 0 10%;
    }

    .header.fixed {
        padding: 5px 0;
    }

    .sbanner {
        padding: 30px 0;
    }

    .solutionrow {
        display: grid;
        text-align: center;
        width: 100%;
    }

    .solutionimg,
    .solutioncont,
    .solutioncont p,
    .advantagesbox,
    .androidbox .squarecont li {
        width: 100%;
    }

    .solutioncont {
        margin-top: 25px;
        padding: 0 20px;
    }

    .advantagecont {
        height: auto;
        padding: 50px 25px;
        width: 100%;
    }

    .netplusadvantages h4,
    .pack h5,
    .medleft ul li,
    .eventsbox h5,
    .footerform .form ul li input::placeholder,
    .footerform .form ul li input,
    .booknow.filter .footerform .form ul li textarea,
    .footerform .form ul li input::placeholder,
    .footerform .form ul li select,
    .footerform .form ul li input {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 1px;
    }

    .form li select {
        letter-spacing: 0.5px;
    }

    .filter .form li select,
    .footerform .form ul li input {
        line-height: 40px;
        height: 40px;
    }

    .medright .react-tabs ul li {
        font-size: 11px;
    }

    .advantages {
        margin-bottom: 15px;
    }

    .medleft ul {
        padding: 10px;
    }

    .solutionimg img,
    .plancont,
    .androidfeature ul li {
        width: 80%;
    }

    .solutioncont h4 {
        font-size: 28px;
        line-height: 32px;
    }

    .aboutimg img {
        border-radius: 100px 0 0 0;
    }

    .planbanner,
    .number.aboutnetplus,
    .plans.smarthome {
        background-attachment: inherit;
    }

    .listpoint p {
        line-height: 18px;
        margin-bottom: 0;
    }

    .gradienticons ul li {
        width: 48%;
    }

    .androidfeature ul {
        flex-wrap: wrap;
    }

    .heading h4,
    .coreadvantages .squarecont li span {
        font-size: 19px;
        line-height: 22px;
    }

    .heading h4 {
        line-height: 24px;
    }

    .packstn .squarecont ul li ul li strong {
        font-size: 21px;
    }

    .packstn .squarecont ul li strong {
        font-size: 24px;
    }

    .bundlebox ul li strong {
        line-height: 30px;
        font-size: 26px;
    }

    .footerform .form ul li:last-child,
    .buttnprop {
        height: 40px;
    }

    .buttnprop span {
        font-size: 14px;
    }

    .bundlebox ul li:before,
    .coreadvantages .squarecont ul li ul li::before {
        width: 30px;
    }

    .bundlebox {
        width: 80%;
        padding: 20px;
    }

    .booknow.filter .footerform .form ul li,
    .hungamabox {
        margin-bottom: 25px;
    }

    .medleft ul li {
        padding: 0 20px;
        margin-left: 15px;
    }

    .medright .react-tabs ul {
        margin-bottom: 25px;
    }

    .eventsbox {
        width: 96%;
    }

    .lg-react-element a img {
        height: 200px;
    }

    .gallery .buttnprop {
        margin-left: 5vw;
    }

    .header {
        overflow-x: hidden;
        overflow-y: visible
    }

    .header.fixed {
        overflow-x: visible !important
    }

    .hemburgur:before {
        height: 180px;
        left: 225%;
        top: -35%;
        width: 180px
    }

    .appbox h3 {
        font-size: 26px;
        line-height: 30px;
    }

    .plans.smarthome {
        padding-bottom: 50px;
    }

    .partnerbox ul li {
        width: 45%;
    }

    .androidtvrow3 img {
        width: 100%;
    }

    .M_algin {
        top: 12vh !important;

    }
}

@media all and (max-width:420px) {
    .web-container {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .circle02 {
        right: 60%;
    }

    .hemburgur {
        width: 35px;
    }

    .logo {
        width: 40%;
    }

    .slidecont {
        right: -8%;
    }

    .netpluslide {
        padding: 0;
    }

    .plans.smarthome .count .App {
        width: 70%;
    }

    .App:last-child,
    .plans.smarthome .count .App:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .App:before {
        opacity: 0;
    }

    .count,
    .menu.active .hemburgermenus .dropdown .dropdown ul li {
        margin: 0;
    }

    .technology ul li {
        width: 40%;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li,
    .hemburgermenus .dropdown .sitemapcol ul li,
    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child {
        font-size: 17px;
    }

    .hemburgermenus .sitemapcol ul:last-child li {
        font-size: 12px;
    }

    .apprgt img,
    .bundlebox {
        width: 100%;
    }

    .bannercont h2,
    .countnum p {
        font-size: 20px;
        line-height: 24px;
    }

    .bannercont {
        width: 50%;
    }

    .labelcont p {
        font-size: 16px;
        line-height: 20px;
        padding: 0;
    }

    .andoidcont h4 {
        font-size: 22px;
        line-height: 26px;
    }

    .androidtvrow:before {
        border-radius: 50px;
    }

    .filter .footerform .form ul li:last-child,
    .androidpoints {
        margin-top: 25px;
    }

    .androidtvrow {
        padding: 25px;
    }

    .packs.coreadvantages .squarecont li,
    .booknow.filter .footerform .form ul li,
    .gradienticons ul li span img,
    .lg-react-element a {
        width: 80%;
    }

    .booknow.filter .footerform .form ul li {
        margin-left: 10%;
    }

    .lg-react-element a img {
        height: auto;
    }

    .business .technobox,
    .androidfeature ul li,
    .technobox {
        width: 90%;
    }

    .technocont {
        text-align: center;
    }

    .count {
        display: table-column-group;
    }

    .App {
        width: 100%;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: solid 1px #6e6e6e;
    }

    .number .title {
        margin-bottom: 20px;
    }

    .btechnoimg h4,
    .technocont h4,
    .circleicons ul li h5 {
        font-size: 24px;
        padding: 0 10px;
        line-height: 30px;
        margin-bottom: 5px;
    }
}

@media all and (max-width:400px) {
    .gap_4 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    .slide05 .circle02 {
        left: 45%;
    }

    .slide05 .listpoint {
        width: 46%;
        box-sizing: border-box;
    }

    .form {
        width: 90%;
        margin: 0 5%;
    }

    .labelcont p {
        font-size: 12px;
        line-height: 16px;
    }

    .pricestn li,
    .circle02 h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .pricestn li:nth-child(1):before {
        font-size: 30px;
    }

    .pricestn li strong {
        font-size: 22px;
        line-height: 30px;
    }

    .circle01,
    .hungamacircle {
        width: 280px;
        height: 280px;
    }

    .pricestn h3 {
        font-size: 13px;
    }

    .circle02 {
        width: 100px;
        height: 100px;
    }

    .pricestn {
        margin: 0;
    }

    .listpoint p {
        font-size: 15px;
        line-height: 20px;
    }

    .slick-track .slick-slide:nth-child(even) .sliderrow .slideimg img {
        top: 9vh;
    }

    .countnum,
    .countnum h3,
    .solutioncont h4,
    .plancont h4 {
        font-size: 28px;
    }

    .App {
        margin-bottom: 10px;
        padding-bottom: 5px;
    }
}

@media all and (max-width:375px) {
    .partnerbox ul li {
        width: 45%;
    }

    .hungamacircle {
        padding: 20px;
    }

    .gradienticons ul li span img {
        width: 65%;
    }

    .listpoint p {
        font-size: 13px;
        line-height: 16px;
    }

    .web-container,
    .ftrbox.address,
    .ftrbox {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .squarecont li {
        padding: 25px 15px;
        width: 46%;
    }

    .squarecont li span,
    .heading h4 {
        font-size: 17px;
        line-height: 20px;
    }

    .squarecont li strong {
        font-size: 30px;
        line-height: 30px;
    }

    .banner:before {
        width: 120px;
        height: 150px;
        right: -20%;
    }

    .title h2,
    .title h3,
    .technologystn .title h3 {
        font-size: 24px;
        line-height: 30px;
    }

    .number .title {
        margin-bottom: 10px;
    }

    .ftrbox,
    .booknow.filter .footerform .form ul li,
    .footer:nth-child(2) .ftrbox {
        width: 100%;
    }

    .circle02 {
        width: 90px;
        height: 90px;
    }

    .fixed .headerstn,
    #contactus .container {
        padding: 0;
    }

    .circleicons ul li {
        width: 100%;
        display: grid;
        justify-content: center;
        text-align: center;
    }

    .circleicons ul li span {
        margin: 0px auto 15px;
    }

    .booknow.filter .footerform .form ul li {
        margin-left: 0;
    }

    .coreadvantages .squarecont li span {
        font-size: 16px;
        line-height: 20px;
    }

    .packstn .squarecont ul li strong {
        font-size: 21px;
    }

    .listpoint ul li {
        font-size: 14px;
        line-height: 18px;
    }
}

@media all and (max-width:360px) {
    .hemburgermenus .sitemapcol ul:last-child li {
        letter-spacing: 2px;
    }

    .gradienticons ul li span {
        width: 80px;
        height: 80px;
    }

    .accordion .accordion-item .title {
        padding: 15px;
        font-size: 14px;
        line-height: 16px;
    }

    .hemburgur span,
    .hemburgur span:nth-child(3) {
        width: 4px;
        height: 4px;
    }

    .hemburgur span:nth-child(1) {
        top: 13px;
    }

    .hemburgur span:nth-child(3) {
        top: 32px;
    }

    .form h2 {
        font-size: 21px;
        line-height: 24px;
    }

    .enquireform2 {
        padding: 20px 0;
        border-radius: 50px 50px 0px 0;
    }

    .ftrbox ul li:first-child {
        margin-bottom: 5px;
    }

    .circle01,
    .hungamacircle {
        width: 265px;
        height: 265px;
    }

    .menu.active .hemburgermenus .dropdown .dropdown ul li,
    .hemburgermenus .dropdown .sitemapcol ul li,
    .hemburgermenus .dropdown .sitemapcol ul:nth-child(1) li:last-child {
        font-size: 15px;
        line-height: 20px;
    }

    .listpoint ul li,
    .hemburgermenus .sitemapcol ul:last-child li {
        font-size: 10px;
        line-height: 12px;
    }

    .hemburgermenus .sitemapcol ul:last-child li {
        line-height: 24px;
    }

    .listpoint ul li strong,
    .title h4 {
        font-size: 16px;
    }

    .slick-dots {
        bottom: 4%;
    }

    .containers svg {
        width: 1rem;
        width: 1rem;
    }

    .servicebox,
    .gradienticons ul li {
        width: 100%;
    }

    .react-tabs__tab-list li {
        padding: 10px;
        font-size: 9px;
        line-height: 10px;
    }

    .apprgt {
        padding: 20px;
        padding-bottom: 0;
    }

    .appbox h3 {
        font-size: 17px;
        line-height: 20px;
    }

    .bannercont h2 {
        font-size: 14px;
        line-height: 16px;
    }
}

@media all and (max-width:320px) {
    .appcta span {
        margin: 0;
        width: 100%;
        text-align: center;
    }

    .highlights ul li {
        width: 100%;
        margin: 5px 0;
    }

    .form h2 {
        font-size: 19px;
        line-height: 22px;
    }

    .heading h4 {
        font-size: 14px;
        line-height: 20px;
    }

    .heading,
    .bundlebox {
        width: 90%;
        margin-left: 5%;
    }
}