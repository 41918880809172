header {
    position: absolute;
    z-index: 9;
    height: 112px;
    width: 100%;
}

.hemburgur {
    width: 35px;
    height: 30px;
    transform: rotate(0deg);
    float: right;
    z-index: 1;
    right: 0;
    background: transparent;
    border: none;
    outline: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.hemburgur span:nth-child(1) {
    top: 10px;
}

.hemburgur span:nth-child(2) {
    top: 22px;
}

.hemburgur span:nth-child(3) {
    top: 34px;
    width: 0;
}

.hemburgur span {
    display: block;
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #000;
    transition: .5s ease-in-out;
    float: left;
    position: absolute;
}

.hemburgur.active span:nth-child(1) {
    transform: rotate(45deg);
    top: 15px;
    left: 5px;
    width: 80%;
    height: 1px;
}

.hemburgur.active span:nth-child(2) {
    opacity: 0;
}

.hemburgur.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 15px;
    left: 5px;
    width: 80%;
    height: 1px;
}

.logo {
    width: 175px;
    position: relative;
    z-index: 1;
}



.navbar ul,
.topcta,
.header.fixed,
.logo a {
    width: 100%;
    float: left;
}

.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop,
.navbar ul li:nth-child(6),
.navbar ul li:nth-child(7) {
    background: #d51e3f;
    color: #fff;
    border-radius: 25px;
    text-align: center;
}


.accImg {
    height: 25px;
    width: auto;
    text-decoration: #d51d3f;
    padding-right: 10px;
}

.navbar {
    padding: 0;
}

.menu,
.navbar ul,
.headerstn,
.topcta,
.topcta ul,
.slideimg,
.jujhar,
.logo a {
    display: flex;
    align-items: center;
}

.navbar ul li span {
    color: #d51d3f;
    display: flex;
    align-items: baseline;
    /* padding-left: 5%;
    padding-right: 5%; */
}
.jujhar,
.slideimg {
    justify-content: center;
}

.booknow.filter .footerform .form ul li:nth-child(2) button.buttnprop{
    font-size: 12px;
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 15px;
}
.navbar ul li a {
    color: #000;
    z-index: 9;
    display: inline-block;
}

.headerstn {
    justify-content: space-between;
    width: 100%;
}
.topcta {
    margin-bottom: 10px;
}

.topcta ul li {
    margin: 0 10px;
    background: #fff;
    border-radius: 25px;
    overflow: hidden;
}

.topcta ul li a {
    padding: 0px 40px;
    line-height: 44px;
    font-size: 15px;
    float: left;
    text-transform: capitalize;
}

.topcta ul li:last-child {
    margin-right: 0;
}

.jujhar {
    width: 150px;
    height: 60px;
    border-radius: 29px 0px 0px 30px;
    background: #fff;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed {
    animation: smoothScroll 1s forwards;
    left: 0;
    position: fixed;
    top: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, .12);
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

.header.fixed {
    background: #fff;
    padding: 0;
}
.fixed .headerstn {
    padding: 10px 0;
}
.fixed .navbar ul li a,
.navbar ul li a:hover,
.navbar .sitemapcol h4 a:hover,
.fixed .navbar .sitemapcol h4 a:hover {
    color: #000;
}
.fixed .navbar ul li:nth-child(7) a,
.navbar ul li:nth-child(7) a:not([href]):hover,
.fixed .navbar ul li:nth-child(6) a,
.navbar ul li:nth-child(6) a:hover,
/* .megamenu.hover .navbar ul li:hover a, */
.fixed .topcta ul li a,
.navbar ul li:hover a, .navbar ul li:nth-child(6) a {
    color: #fff;
}

.fixed .topcta ul li {
    background: #ea354c;
}

.fixed .hemburgur span {
    background: #000 !important;
}

.logo img:last-child {
    position: absolute;
    left: 0;
    opacity: 0;
    transition: ease all 0.5s;
}

.fixed .logo {
    width: 130px;
}

.fixed .logo img:last-child {
    opacity: 1;
    width: 95%;
}

.fixed .jujhar {
    background: #ebebeb;
}

.fixed .logo img:first-child {
    opacity: 0;
}

.jujhar,
.topcta ul li,
.navbar ul li a,
.dropdown,
.logo,
.headerstn {
    transition: ease all 0.5s;
}

.buttnprop:hover span {
    top: 0;
    left: 0;
}

.buttnprop:hover span {
    background: #292a2b;
    color: #d6223a;
}

.buttnprop:hover {
    border-color: #292a2b;
}

.chooseplan .title h4, .circlimg h2 strong {
    color: #fff;
}


.chooseplan .buttnprop:hover span {
    background: #000;
    color: #fff;
}

.chooseplan .buttnprop:hover {
    border-color: #000;
}

/* .chooseplan .buttnprop {border-color: #fff;} */
.topcta ul li:before {
    content: '';
    background: #292a2b;
    width: 100%;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    transition: ease all 0.5s;
    z-index: 1;
}

.topcta ul li:hover:before {
    height: 100%;
}

.topcta ul li:hover a, .hover .navbar ul li:hover a {
    color: #fff;
}
.hover .navbar ul li a{
    color: #c3c3c3;
}

.topcta ul li a,
.headerstn {
    z-index: 2;
}

.dropdown {
    align-items: flex-end;
    display: flex;
    height:56vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all .5s ease;
    visibility: hidden;
    width: 100%;
    display: none;
}

.navbar ul li:hover .dropdown,
.menu.active .hemburgermenus .dropdown {
    opacity: 1;
    visibility: visible;
    display: block;
}

.submenu {
    display: none;
    float: left;
    opacity: 0;
    visibility: hidden;
    width: 100%;
     background: linear-gradient(120deg, rgb(106 61 226 / 90%), rgb(175 44 126 / 90%) 50%, rgb(236 48 71 / 90%));
    height: 100%;
}

.menu.active .hemburgermenus .submenu,
.navbar ul li:hover .dropdown .submenu {
    display: block;
    height: 100%;
    opacity: 1;
    visibility: visible;
}
.menubox {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 65%;
    padding-top: 20vh;
    justify-content: space-around;
}
.dropdown li a {
    color: #fff !important;
}
.dropdown li {
    margin: 10px 0;
}

.header {
    padding: 20px 0;
}


.navbar .sitemapcol ul {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0;
}

.navbar .sitemapcol ul li {
    padding: 0;
    margin: 0;
    width: 100%;
    line-height: 30px;
    font-size: 14px;
    color: #fff;
    background: transparent;
    border-radius: 0;
    text-align: left;
}

.sitemapcol h4 {
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    text-transform: uppercase;
}

.navbar ul li a.active:before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #d51e3f;
    transition: ease all 0.5s;
}

.navbar ul li .dropdown .sitemapcol {
    background: transparent;
    border-radius: 0;
    text-align: left;
}

.hemburgermenus {
    width: 300px;
    float: left;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 9;
    border-radius: 0 0 0 100px;
    opacity: 0;
    visibility: hidden;
}

.menu.active .hemburgermenus {
    opacity: 1;
    visibility: visible;
}

.hemburgermenus .dropdown {
    width: 100%;
    position: static;
    transform: scale(1, 0);
    padding: 25px 50px;
    height: auto;
    float: left;
    padding-bottom: 70px;
}

.menu.active .hemburgermenus .dropdown {
    transform: scale(1, 1);
}

.hemburgermenus .dropdown .sitemapcol ul li {
    border-bottom: solid 1px rgb(241 241 241 / 25%);
    padding: 5px 0;
    margin: 5px 0;
}

.hemburgermenus .dropdown .sitemapcol ul li:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.dropdown li a:hover, .navbar ul li .dropdown .sitemapcol h4:hover a {
    color: #000 !important;
}

.hemburgermenus .dropdown .submenu {
    background: transparent;
    padding: 0;
}

svg {
    width: 100%;
    height: auto;
}

.navbar ul li:nth-child(7), .navbar ul li:nth-child(6), .appcta span {
    overflow: hidden;
}

.appcta span::before, .navbar ul li:nth-child(7):before, .navbar ul li:nth-child(6):before {
    content: '';
    width: 100%;
    height: 0;
    background: #000;
    position: absolute;
    left: 0;
    transition: ease all 0.5s;
    top: 0;
}

.navbar ul li:nth-child(7):hover:before, .navbar ul li:nth-child(6):hover:before, .appcta span:hover::before {
    height: 100%;
}
.dropdown .logo {
    position: absolute;
    top: 2vh;
    left: 6vw;
    bottom: inherit;
    margin: 0;
}

.dropdown .logo a.active:before {
    display: none;
}

.dropdown .logo svg {
    width: 100%;
    margin: 0;
}

#header.hover .logo path  {
    fill: #fff !important;
}
.appcta span, .submenu .container, .hemburgur, .navbar ul li a, .topcta ul li a, .headerstn, .navbar ul li:nth-child(7), .navbar ul li:nth-child(6) {
 position: relative;
}
.fixed .dropdown .logo {
    left: -0.7vh;
    top: 0.7vh;
}
.logo path{
    transition: delay 1s;
}
.hover .navbar ul li:nth-child(6), .hover .navbar ul li:nth-child(7) {
    background: #fff;
}

.hover .navbar ul li:nth-child(6) a, .hover .navbar ul li:nth-child(7) a {
    color: #d51e3f;
}